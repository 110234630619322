/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import AutoComplete from '@medical/components/AutoComplete'
import CustomCalendarPicker from '@medical/components/CalendarPicker'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import InputTextarea from '@medical/components/InputTextarea'
import Spinner from '@medical/components/Spinner'
import {
  FORMAT_TIME_NOT_UTC,
  HOLIDAY_URL,
  objectTargetConstant,
  SHIFT_FORM_STEP,
} from '@medical/constant'
import { useAxios } from '@medical/hooks'
import {
  convertClinicalTimeSheet,
  convertDoctorsConnection,
  thisMonthHolidays,
} from '@medical/libs'
import {InputNumber, Switch, DatePicker, TimePicker, Modal} from 'antd'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import {
  CLINIC_TIME_SHEET,
  DOCTOR_CONNECTION,
  GET_SALARY_BY_TIME,
} from '../CreateAvailableShift/CreateAvailableShift.graphql'
import {
  STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME,
  STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE,
} from '../CreateWorkSchedule/CreateWorkSchedule.graphql'
import { ShiftFormDoctorPicker } from './ShiftFormDoctorPicker'
import ShiftFormRecruitmentCategory from './ShiftFormRecruitmentCategory'
import ShiftFormSplits from './ShiftFormSplits'
import ShiftFormStaffComment from './ShiftFormStaffComment'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { validateStartTimeEndTime } from '@medical/constant/utilities'
import { Checkbox as CheckboxAntd } from 'antd';

const ShiftForm = ({
  i18n,
  stateUnpublished = {},
  availableShift = {},
  doctors,
  doctorsList,
  clinicalDepartments,
  onSubmit,
  date,
  title,
  submitLabel,
  confirmationMessage,
  workSchedule,
  isMoneyPermitted,
  isCreateAvailableShift,
  isChangeDateFirstTime,
  isChangeRecruitmentShiftsStatusPermitted,
  popup,
  isAddCommentPermitted,
  isCreateWorkSchedule,
  waitingAvailableShift,
  isChangeConfirmedShiftsStatusPermitted,
  state,
}) => {
  const [isHideWarningText, setHideWarningText] = useState(false)
  const [informTime, setInformTime] = useState(moment().format())
  const [selectingClinicId, setSelectingClinicId] = useState('')
  const [doctorSelecting, setDoctorSelecting] = useState({})
  const [showModalDoctorNotValid, setShowModalDoctorNotValid] = useState(false)
  const [
    selectingClinicDepartmentId,
    setSelectingClinicDepartmentId,
  ] = useState(state?.clinicalDepartment?.id || '')
  const [clinicalDepartmentId, setCLinicalDepartmentId] = useState('')
  const [timePick, setTimePick] = useState({
    startTime:
      availableShift && availableShift.startTime
        ? moment(availableShift.startTime).format(FORMAT_TIME_NOT_UTC)
        : `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
    endTime: `${moment().format('YYYY-MM-DD')}T` + `09:01:00`,
  })
  const client = useApolloClient()
  useEffect(() => {
    setSelectingClinicId(
      availableShift &&
        availableShift.clinicalDepartment &&
        availableShift.clinicalDepartment.clinic
        ? availableShift.clinicalDepartment.clinic.id
        : stateUnpublished && stateUnpublished.clinicalDepartment
        ? stateUnpublished.clinicalDepartment.clinic.id
        : ''
    )
    setSelectingClinicDepartmentId(
      availableShift && availableShift.clinicalDepartment
        ? availableShift.clinicalDepartment.id
        : stateUnpublished &&
          stateUnpublished.clinicalDepartment &&
          stateUnpublished.clinicalDepartment.id
        ? stateUnpublished.clinicalDepartment.id
        : ''
    )
    if (!isCreateWorkSchedule) {
      setInformTime(date ? moment(date).format() : moment.format())
    }
  }, [availableShift, date])

  const recruitmentCategories = [
    {
      label: '募集項目なし',
      value: 'NO_RECRUITMENT',
    },
    {
      label: 'MRT',
      value: 'MRT',
    },
    {
      label: '民間医局',
      value: 'PRIVATE_CLINIC',
    },
    {
      label: 'その他 ',
      value: 'OTHER',
    },
  ]

  const recruitmentStatuses = [
    {
      label: '必要なし',
      value: 'NOT_REQUIRED',
    },
    {
      label: '掲載取り下げ',
      value: 'CANCEL_POSTING',
    },
    {
      label: '掲載中',
      value: 'POSTED',
    },
    {
      label: '未',
      value: 'NOT_POSTED_YET',
    },
  ]

  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    return !min.isSameOrBefore(current)
  }
  const disabledHours = time => {
    const result = []
    const timeStamp = moment().format('HH')
    for (let i = 0; i < 24; i += 1) {
      if (i <= timeStamp) {
        result.push(i)
      }
    }
    return result
  }

  const disabledMinute = value => {
    const result = []
    for (let i = 1; i < value; i += 1) {
      result.push(i)
    }
    return result
  }

  const findRecruitmentCategory = value =>
    recruitmentCategories.find(it => it.value === value)
  const findRecruitmentStatus = value =>
    recruitmentStatuses.find(it => it.value === value)

  const validationSchema = Yup.object().shape({
    clinicalDepartment: Yup.object()
      .nullable()
      .required(i18n.t('validation.clinicalDepartment.required')),
    doctor: workSchedule
      ? Yup.object()
          .nullable()
          .required(i18n.t('validation.doctor.required'))
      : undefined,
    date: Yup.date(i18n.t('validation.date.required')).required(''),
    startTime: Yup.date('Should be string').required(''),
    splits: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required(i18n.t('validation.date.required')),
        hourlyWage: isMoneyPermitted
          ? Yup.number(i18n.t('validation.hourlyWage.number')).required(
              i18n.t('validation.hourlyWage.required')
            )
          : undefined,
      })
    ),
    splitComments: Yup.array().of(
      Yup.object().shape(
        {
          type: Yup.string().when('text', {
            is: text => text && text.length > 0,
            then: Yup.string(i18n.t('validation.comment.required')).required(
              i18n.t('validation.dropdownCommentType.required')
            ),
          }),
          text: Yup.string().when('type', {
            is: value => value && value.length > 0,
            then: Yup.string(i18n.t('validation.comment.required'))
              .trim()
              .required(i18n.t('validation.comment.required'))
              .max(500, i18n.t('validation.title.max', { max: 500 })),
          }),
        },
        ['type', 'text']
      )
    ),
    splitRecruitmentCategories: Yup.array().of(
      Yup.object().shape(
        {
          category: Yup.string().when('status', {
            is: text => text && text.length > 0,
            then: Yup.string().required('募集項目を入力してください'),
          }),
          status: Yup.string().when('category', {
            is: value => value && value.length > 0,
            then: Yup.string()
              .trim()
              .required('募集項目を入力してください'),
          }),
          description: Yup.string().when('category', {
            is: value => value && value.length > 0 && value === 'OTHER',
            then: Yup.string()
              .trim()
              .required('募集項目を入力してください')
              .max(500, i18n.t('validation.title.max', { max: 500 })),
          }),
        },
        ['category', 'status', 'description']
      )
    ),
    numberOfRooms: workSchedule
      ? undefined
      : Yup.number()
          .required(i18n.t('validation.numberOfRooms.required'))
          .min(1, i18n.t('validation.numberOfRooms.min'))
          .max(10, i18n.t('validation.numberOfRooms.max')),
    comment: Yup.string(),
    objectTarget: Yup.string().nullable(),
    workPattern: Yup.mixed().when('objectTarget', {
      is: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
      then: Yup.mixed().test(
        'validateWorkPartten',
        i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
        val => val && val.length > 0
      ),
    }),
    specificDoctors: Yup.mixed().when('objectTarget', {
      is: objectTargetConstant.SET_FOR_EACH_DOCTOR,
      then: Yup.mixed().test(
        'validateSelectDoctor',
        i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
        val => val && val.length > 0
      ),
    }),
    doctorSubsidy: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          title: Yup.string()
            .trim()
            .nullable()
            .required(i18n.t('validation.doctorSubsidy')),
          money: Yup.number()
            .nullable()
            .required(i18n.t('validation.doctorSubsidy')),
        })
      ),
  })
  const {
    loading: holidayLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(informTime)
        .startOf('month')
        .toISOString(),
      timeMax: moment(informTime)
        .endOf('month')
        .toISOString(),
    },
    dependencies: informTime,
  })

  const holidays = thisMonthHolidays({
    date: informTime,
    holidaysData: pathOr([], ['items'], holidaysData),
  })
  let isHoliday = false
  const [
    onSubmitCreateAvailableShiftMessage,
    setOnSubmitCreateAvailableShiftMessage,
  ] = useState(confirmationMessage)

  if (isCreateAvailableShift) {
    for (const element of holidays) {
      if (element.date === moment(informTime).format('YYYY-MM-DD'))
        isHoliday = true
    }
  }

  const [startTimeCheckHourlyWage, setStartTimeCheckHourlyWage] = useState({
    time: `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
    index: 0,
    isEndTime: false,
  })
  const [currentHourlyWage, setCurrentHourlyWage] = useState([0, 0, 0, 0])
  useEffect(() => {
    if (!isCreateAvailableShift) {
      for (const element of holidays) {
        if (element.date === moment(informTime).format('YYYY-MM-DD'))
          isHoliday = true
      }
    }
  }, [informTime])
  // Call api get clinic time sheet
  const { loading, error, data, refetch } = useQuery(CLINIC_TIME_SHEET, {
    variables: {
      clinicalDepartmentId: selectingClinicDepartmentId,
      date: informTime,
      isHoliday,
    },
  })
  // Call api get hourlywage by value
  const {
    loading: hourlyWageLoading,
    error: hourlyWageError,
    data: hourlyWageData,
    refetch: hourlyWageRefetch,
  } = useQuery(GET_SALARY_BY_TIME, {
    variables: {
      clinicalDepartmentId: selectingClinicDepartmentId,
      date:
        informTime && timePick && timePick.startTime
          ? `${informTime.split('T')[0]}T${
              startTimeCheckHourlyWage.time.split('T')[1]
            }`
          : '',
      isHoliday,
    },
  })
  // Call api get doctor default hourly wage
  const {
    data: doctorDefaultHourlyWage,
    refetch: doctorDefaultHourlyWageRefetch,
  } = useQuery(STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE, {
    variables: {
      doctorId: doctorSelecting && doctorSelecting.id ? doctorSelecting.id : '',
      clinicalDepartmentId,
      date:
        informTime && timePick && timePick.startTime
          ? `${informTime.split('T')[0]}T${
              startTimeCheckHourlyWage.time.split('T')[1]
            }`
          : '',
      isHoliday,
    },
  })

  useEffect(() => {
    if (isCreateAvailableShift || isCreateWorkSchedule) {
      setStartTimeCheckHourlyWage({
        time: `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
        index: 0,
        isEndTime: false,
      })
    }
  }, [selectingClinicDepartmentId, informTime])
  useEffect(() => {
    ;(async function() {
      if (isCreateAvailableShift) {
        // const data = await hourlyWageRefetch()
        await hourlyWageRefetch()
          .then(res => {
            const tempList = currentHourlyWage
            if (!startTimeCheckHourlyWage.isEndTime) {
              tempList[startTimeCheckHourlyWage.index] =
                res && res.data && res.data.availableShiftDefaultHourlyWage
                  ? res.data.availableShiftDefaultHourlyWage
                  : 0
            } else {
              tempList[startTimeCheckHourlyWage.index + 1] =
                res && res.data && res.data.availableShiftDefaultHourlyWage
                  ? res.data.availableShiftDefaultHourlyWage
                  : 0
            }

            setCurrentHourlyWage([...tempList])
          })
          .catch(err => {})
      }
    })()
  }, [
    hourlyWageData,
    selectingClinicDepartmentId,
    informTime,
    timePick,
    startTimeCheckHourlyWage,
  ])

  useEffect(() => {
    ;(async function() {
      if (isCreateWorkSchedule) {
        await doctorDefaultHourlyWageRefetch()
          .then(res => {
            const tempList = currentHourlyWage
            if (!startTimeCheckHourlyWage.isEndTime) {
              tempList[startTimeCheckHourlyWage.index] =
                res && res.data && res.data.doctorDefaultHourlyWage
                  ? res.data.doctorDefaultHourlyWage
                  : 0
            } else {
              tempList[startTimeCheckHourlyWage.index + 1] =
                res && res.data && res.data.doctorDefaultHourlyWage
                  ? res.data.doctorDefaultHourlyWage
                  : 0
            }

            setCurrentHourlyWage([...tempList])
          })
          .catch(err => {})
      }
    })()
  }, [
    doctorDefaultHourlyWage,
    selectingClinicDepartmentId,
    informTime,
    timePick,
    startTimeCheckHourlyWage,
  ])
  useEffect(() => {
    if (selectingClinicId && informTime && isCreateAvailableShift) {
      refetch()
    }
  }, [selectingClinicId, informTime])
  useEffect(() => {
    if (date && isChangeDateFirstTime) {
      setInformTime(moment(date).format())
    }
  }, [date])
  useEffect(() => {
    setHideWarningText(
      validateStartTimeEndTime(timePick.startTime, timePick.endTime)
    )
  }, [timePick])
  const [
    checkStatus,
    { data: statusData, loading: statusLoading, error: statusError },
  ] = useMutation(STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME)

  if (holidayLoading) return <ProgressSpinner />
  if (error || holidaysError) return <ErrorComponent error={error} />
  // const hourlyWage =
  //   hourlyWageData && hourlyWageData.availableShiftDefaultHourlyWage
  //     ? hourlyWageData.availableShiftDefaultHourlyWage
  //     : 0
  const handleClickAddHourlyWage = (dataTime, index, splits) => {
    if (isCreateAvailableShift || isCreateWorkSchedule) {
      const timePre = moment(dataTime).format('HH:mm:ss')

      const tempList = currentHourlyWage
      tempList[index - 1] = splits[index - 1].hourlyWage
      for (let i = 0; i < splits.length; i += 1) {
        if (i !== index - 1) {
          tempList[i] = splits[i].hourlyWage
        }
      }
      setCurrentHourlyWage([...tempList])
      setStartTimeCheckHourlyWage({
        time: `${moment().format('YYYY-MM-DD')}T${timePre}`,
        index,
        isEndTime: false,
      })
    }
  }
  const listObjectTarget = [
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachDoctor'
      ),
      value: objectTargetConstant.SET_FOR_EACH_DOCTOR,
    },
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachWorkPartten'
      ),
      value: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
    },
  ]

  const listWorkPartten = [
    {
      value: 'ALL_WORKPATTTEN',
      description: i18n.t('main.ALL_WORK_PATTERN'),
    },
    {
      value: 'PERMANENT_DOCTOR',
      description: i18n.t('main.PERMANENT_DOCTOR'),
    },
    {
      value: 'REGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'IRREGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.IRREGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'TEMPORARY_DOCTOR',
      description: i18n.t('main.TEMPORARY_DOCTOR'),
    },
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={{
        isSendMail: false,
        confirm: false,
        doctor: isCreateAvailableShift ? doctorSelecting : '',
        doctors,
        isHoliday,
        clinicId: isCreateAvailableShift ? selectingClinicId : '',
        clinicalDepartments,
        date:
          isCreateAvailableShift && !availableShift
            ? informTime
            : state?.date
            ? state.date
            : availableShift.startTime || date.toDate(),
        clinicalDepartment:
          isCreateAvailableShift && selectingClinicDepartmentId
            ? clinicalDepartments.find(
                ({ id }) => id === selectingClinicDepartmentId
              )
            : availableShift.clinicalDepartment
            ? clinicalDepartments.find(
                ({ id }) => id === availableShift.clinicalDepartment.id
              )
            : stateUnpublished && stateUnpublished.clinicalDepartment
            ? stateUnpublished.clinicalDepartment
            : state?.clinicName
            ? clinicalDepartments.find(
                item => item.clinic.name === state?.clinicName
              )
            : '',
        startTime: waitingAvailableShift
          ? waitingAvailableShift.startTime
          : availableShift.startTime || date.toDate(),
        splits: availableShift.splits || [
          {
            date: stateUnpublished.endTime
              ? stateUnpublished.endTime
              : waitingAvailableShift && waitingAvailableShift.endTime
              ? waitingAvailableShift.endTime
              : date
                  .clone()
                  .add(SHIFT_FORM_STEP, 'minute')
                  .toDate(),
            hourlyWage: 0,
            isBreakTime: false,
          },
        ],
        numberOfRooms: 1,
        unpermittedDoctors: [],
        comment: state?.comment
          ? state.comment
          : availableShift?.comment
          ? availableShift.comment
          : '',
        splitComments: [
          {
            text: '',
            type: '',
          },
        ],
        splitRecruitmentCategories: [
          {
            category: '',
            status: '',
            description: '',
          },
        ],
        // dateInform:
        //   selectingClinicId === ''
        //     ? ''
        //     : data.clinicTimeSheet &&
        //       data.clinicTimeSheet.length === 1 &&
        //       data.clinicTimeSheet[0].isAvailable
        //     ? `${data.clinicTimeSheet[0].startTime} - ${data.clinicTimeSheet[0].endTime}`
        //     : i18n.t('staff.createShift.available'),
        dateInform: convertClinicalTimeSheet(data, selectingClinicId),
        isPosted: state
          ? state.isPublished
          : availableShift && Object.keys(availableShift).length !== 0
          ? availableShift.isPublished
          : true,
        isSpecial: availableShift.isSpecial || false,
        isEarlySpecial: availableShift.isEarlySpecial || false,
        isDoubleRecruitment: availableShift.isDoubleRecruitment || false,
        doctorSubsidy: availableShift?.doctorSubsidy || [],
        isSetIndividually: state?.isSetIndividually
          ? state.isSetIndividually
          : isCreateAvailableShift
          ? false
          : availableShift.isSetIndividually,
        objectTarget:
          !isCreateAvailableShift && availableShift.isSetIndividually
            ? availableShift.objectTarget
            : state?.objectTarget
            ? state?.objectTarget
            : undefined,
        workParttenSearch: '',
        textSearchDoctorByName: '',
        isShowListBox: false,
        specificDoctors: state?.specificDoctors ? state.specificDoctors : [],
        workPattern: state?.workPattern ? state.workPattern : [],
        doctorsList,
        isSettingHideShift: false,
        dateSettingHideShift: '',
        timeSettingHideShift: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          touched,
          errors,
          setFieldValue,
          setTouched,
          validateForm,
          handleChange,
          values,
        } = formikProps
        const props = { ...formikProps, i18nKey: 'staff.createShift' }
        return (
          <div>
            <div className='modal-title'>{title}</div>
            <div className='tr'>
              <div className='th'>{i18n.t('staff.createShift.clinic')}</div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartment'
                  field='clinicName'
                  allSuggestions={clinicalDepartments}
                  suggestionName='clinicalDepartments'
                  width='60vw'
                  maxWidth='386px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                  setCurrentClinicData={val => {
                    if (isCreateAvailableShift) {
                      setSelectingClinicId(
                        val && val.clinic ? val.clinic.id : ''
                      )
                    } else {
                      setFieldValue(
                        'clinicId',
                        val && val.clinic ? val.clinic.id : ''
                      )
                    }
                    setSelectingClinicDepartmentId(val ? val.id : '')
                    setCLinicalDepartmentId(val ? val.id : '')
                    setTimePick({
                      startTime:
                        availableShift && availableShift.startTime
                          ? moment(availableShift.startTime).format(
                              FORMAT_TIME_NOT_UTC
                            )
                          : `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
                      endTime: `${moment().format('YYYY-MM-DD')}T` + `09:01:00`,
                    })
                  }}
                />
                <div className='alert'>
                  {touched.clinicalDepartment && errors.clinicalDepartment}
                </div>
              </div>
            </div>
            {workSchedule && (
              <div className='tr'>
                <div className='th'>{i18n.t('staff.createShift.doctor')}</div>
                <div className='td'>
                  <AutoComplete
                    {...formikProps}
                    name='doctor'
                    field='fullNameEmail'
                    searchFields={['fullName', 'email']}
                    allSuggestions={values.doctors}
                    suggestionName='doctors'
                    width='60vw'
                    maxWidth='386px'
                    placeholder={
                      doctors.length > 0 ? '勤務医師を選択' : '対象データなし'
                    }
                    setDoctorSelect={async val => {
                      if (val?.countedDoctorLastWorkShift?.lastWorkday) {
                        const dateAfterEighteenMonths = moment(val.countedDoctorLastWorkShift.lastWorkday)
                          .add(18, 'months')
                          .startOf('D')
                          .toISOString()
                        const now = moment().toISOString();
                        if (now > dateAfterEighteenMonths) {
                          setShowModalDoctorNotValid(true);
                        }
                      }
                      setDoctorSelecting(val)
                      if (typeof val === 'string') {
                        try {
                          const { data: dataConnection } = await client.query({
                            query: DOCTOR_CONNECTION,
                            variables: {
                              where: {
                                AND: [
                                  {
                                    OR: [
                                      {
                                        unSigned_contains:
                                          typeof val === 'string'
                                            ? val
                                                .replace(/ /g, '')
                                                .replace(/　/g, '')
                                                .toLowerCase()
                                            : '',
                                      },
                                      {
                                        email_contains: val,
                                      },
                                    ],
                                  },
                                ],
                                registrationStatus_in: [
                                  'CREATED_BY_STAFF',
                                  'ACCEPTED',
                                ],
                              },
                              first: 10,
                              skip: 0,
                              orderBy: 'unSigned_ASC',
                            },
                            fetchPolicy: 'network-only',
                          })
                          setFieldValue(
                            'doctors',
                            convertDoctorsConnection(
                              dataConnection.doctorsConnection.edges
                            )
                          )
                        } catch (err) {
                          popup.error()
                        }
                      }
                    }}
                  />
                  <div className='alert'>{touched.doctor && errors.doctor}</div>
                </div>
              </div>
            )}
            <CustomCalendarPicker
              {...formikProps}
              label={i18n.t('staff.createShift.date')}
              name='date'
              onChange={val => {
                handleChange(val)
                setInformTime(
                  moment(val.value)
                    .format()
                    .split('+')[0]
                )
                if (!isCreateAvailableShift) {
                  setTimePick({
                    endTime: `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
                    startTime: `${moment().format('YYYY-MM-DD')}T` + `09:01:00`,
                  })
                }
              }}
            />

            {isCreateAvailableShift ? (
              // <TextInput
              //   {...formikProps}
              //   name='dateInform'
              //   disabled
              //   label={i18n.t('staff.createShift.timeSheet')}
              //   defaultValue={values.dateInform}
              //   className=""
              // />
              <InputTextarea
                {...formikProps}
                disabled
                name='dateInform'
                label={i18n.t('staff.createShift.timeSheet')}
                rows='3'
              />
            ) : null}
            {isCreateWorkSchedule ? (
              <div className='tr'>
                <div
                  style={{
                    color: 'red',
                  }}
                  className='th'
                >
                  二診目登録
                </div>
                <div className='td checkbox-color-border'>
                  <CheckboxAntd
                    checked={values.isDoubleRecruitment}
                    onChange={e => {
                      setFieldValue('isDoubleRecruitment', e.target.checked)
                    }}
                  />
                </div>
              </div>
            ) : null}

            <ShiftFormSplits
              {...formikProps}
              i18n={i18n}
              isMoneyPermitted={isMoneyPermitted}
              isCreateAvailableShift={isCreateAvailableShift}
              availableShift={availableShift}
              currentHourlyWage={currentHourlyWage}
              isCreateWorkSchedule={isCreateWorkSchedule}
              workSchedule={workSchedule}
              isHideWarningText={isHideWarningText}
              getStartTimeValue={async (val, index) => {
                const formatTimePick = moment(val)
                  .format()
                  .split('+')[0]
                if (
                  index === 0 &&
                  (isCreateAvailableShift || isCreateWorkSchedule)
                ) {
                  setStartTimeCheckHourlyWage({
                    time: formatTimePick,
                    index,
                    isEndTime: false,
                  })
                }
                setTimePick({
                  ...timePick,
                  startTime: moment(val)
                    .format()
                    .split('+')[0],
                })
              }}
              handleClickAddHourlyWage={handleClickAddHourlyWage}
              handleDeleteHourlyWage={data => {}}
              getEndTimeValue={async (val, index) => {
                const formatTimePick = moment(val)
                  .format()
                  .split('+')[0]

                if (
                  values.splits &&
                  values.splits.length > 1 &&
                  (isCreateAvailableShift || isCreateWorkSchedule) &&
                  values.splits[index + 1] &&
                  index < 4
                ) {
                  setStartTimeCheckHourlyWage({
                    time: formatTimePick,
                    index,
                    isEndTime: true,
                  })
                }

                setTimePick({
                  ...timePick,
                  endTime: moment(val)
                    .format()
                    .split('+')[0],
                })
              }}
            />
            {workSchedule && values.doctorSubsidy ? (
              <div>
                <FieldArray
                  name='doctorSubsidy'
                  render={arrayHelpers => (
                    <div>
                      {values.doctorSubsidy.map((item, index) => (
                        <div className='doctor-subsidy-container'>
                          <div className='doctor-subsidy__item'>
                            {i18n.t('staff.workSchedule.doctorSubsidy')}
                          </div>
                          <div className='doctor-subsidy__item'>
                            <div className='title'>
                              {i18n.t('staff.workSchedule.subsidyName')}
                            </div>
                            <div>
                              <InputText
                                value={item.title}
                                onChange={e => {
                                  setFieldValue(
                                    `doctorSubsidy[${index}].title`,
                                    e.target.value
                                  )
                                }}
                              />
                              <ErrorMessage
                                name={`doctorSubsidy[${index}].title`}
                                component='div'
                                className='signup-alert'
                              />
                            </div>
                          </div>
                          <div className='doctor-subsidy__item'>
                            <div className='title'>
                              {i18n.t('staff.workSchedule.subsidyValue')}
                            </div>
                            <div>
                              <InputNumber
                                size='medium'
                                value={item.money}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                type='tel'
                                allowClear
                                min={0}
                                step={10}
                                onChange={e => {
                                  setFieldValue(
                                    `doctorSubsidy[${index}].money`,
                                    e
                                  )
                                }}
                              />
                              <ErrorMessage
                                name={`doctorSubsidy[${index}].money`}
                                component='div'
                                className='signup-alert'
                              />
                            </div>
                          </div>
                          <div className='doctor-subsidy__item'>
                            <Button
                              type='button'
                              icon='pi pi-trash'
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                            />
                          </div>
                          {/* <div className='errors-container'>
                           
                           
                          </div> */}
                        </div>
                      ))}
                    </div>
                  )}
                />
                <Button
                  type='button'
                  icon='pi pi-plus'
                  label={i18n.t('staff.workSchedule.btnSubsidy')}
                  onClick={() => {
                    setFieldValue('doctorSubsidy', [
                      ...values.doctorSubsidy,
                      {
                        title: '',
                        money: null,
                      },
                    ])
                  }}
                />
              </div>
            ) : null}
            {!workSchedule && (
              <Spinner
                {...props}
                inputWidth='auto'
                name='numberOfRooms'
                label={i18n.t('staff.createShift.numberOfRooms')}
                min={1}
                max={10}
                keyfilter='int'
              />
            )}
            {isAddCommentPermitted && (
              <ShiftFormStaffComment i18n={i18n} formikProps={formikProps} />
            )}

            <InputTextarea
              {...formikProps}
              name='comment'
              label={i18n.t('main.workDetail')}
            />

            {isCreateAvailableShift || isCreateWorkSchedule ? (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createShift.postStatus')}
                </div>
                <div className='td'>
                  <div className='checkbox-item toggle-published'>
                    {isChangeRecruitmentShiftsStatusPermitted ||
                    isChangeConfirmedShiftsStatusPermitted ? (
                      <Switch
                        checkedChildren='掲載中'
                        unCheckedChildren='未掲載'
                        name='isPosted'
                        checked={values.isPosted}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isPosted', val)
                        }}
                        disabled={values.isSetIndividually}
                      />
                    ) : (
                      <Switch
                        checkedChildren='掲載中'
                        unCheckedChildren='未掲載'
                        name='isPosted'
                        checked={values.isPosted}
                        disabled={values.isSetIndividually}
                        onChange={() => {
                          popup.error('募集シフト掲載選択の権限がありません。')
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {/* set the day to unpublish available shift */}
            {isCreateAvailableShift && (
              <div className='tr'>
                <div className='th'>自動未掲載設定</div>
                <div className='td'>
                  <div className='checkbox-item toggle-published'>
                    <Switch
                      name='isSettingHideShift'
                      checkedChildren='設定'
                      unCheckedChildren='未設定'
                      defaultChecked={values.isSettingHideShift}
                      onChange={val => {
                        handleChange(val)
                        setFieldValue('isSettingHideShift', val)
                      }}
                      disabled={
                        isChangeRecruitmentShiftsStatusPermitted ||
                        isChangeConfirmedShiftsStatusPermitted
                          ? values.isPublished
                          : !values.isPublished
                      }
                      checked={values.isSettingHideShift}
                    />
                  </div>
                </div>
              </div>
            )}
            {values.isSettingHideShift && (
              <>
                {' '}
                <div className='tr'>
                  <div className='th'>未掲載日</div>
                  <div className='td'>
                    <DatePicker
                      onChange={e => {
                        setFieldValue('dateSettingHideShift', e)
                        setFieldValue('timeSettingHideShift', '')
                      }}
                      value={values.dateSettingHideShift}
                      locale={locale}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </div>
                </div>
                <div className='tr'>
                  <div className='th'>未掲載時間</div>
                  <div className='td'>
                    <TimePicker
                      // onChange={handleFilter}
                      placeholder='時間を設定'
                      format='HH:mm'
                      onChange={e => {
                        setFieldValue('timeSettingHideShift', e)
                      }}
                      disabled={!values.dateSettingHideShift}
                      value={values.timeSettingHideShift}
                      hideDisabledOptions
                      disabledMinutes={() => disabledMinute(60)}
                      allowClear={false}
                      showNow={false}
                    />
                  </div>
                </div>
              </>
            )}

            {isCreateAvailableShift && (
              <ShiftFormRecruitmentCategory
                i18n={i18n}
                formikProps={formikProps}
                state={state}
              />
            )}
            {isCreateAvailableShift && (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createShift.setPublicRecruitmentShift')}
                </div>
                <div className='td'>
                  <Checkbox
                    onChange={e => {
                      if (!isChangeRecruitmentShiftsStatusPermitted) {
                        popup.error('募集シフト掲載選択の権限がありません。')
                      } else {
                        setFieldValue('isSetIndividually', e.checked)
                        if (e.checked) {
                          setFieldValue(
                            'objectTarget',
                            objectTargetConstant.SET_FOR_EACH_DOCTOR
                          )
                        } else {
                          setFieldValue('objectTarget', undefined)
                        }
                        setFieldValue('isPosted', false)
                      }
                    }}
                    checked={values.isSetIndividually}
                  />
                </div>
              </div>
            )}

            {isCreateAvailableShift && values.isSetIndividually && (
              <ShiftFormDoctorPicker
                {...formikProps}
                i18n={i18n}
                hasPermitted={() => {
                  if (!isChangeRecruitmentShiftsStatusPermitted) {
                    popup.error(
                      i18n.t('staff.scheduledShiftGroup.errorMessagePermision')
                    )
                    return false
                  }
                  return true
                }}
                listObjectTarget={listObjectTarget}
                listWorkPartten={listWorkPartten}
                isDisplay={values.isSetIndividually}
                formikProps={formikProps}
                isCreateAvailableShift={isCreateAvailableShift}
                state={state}
              />
            )}

            <div className='modal-bottom'>
              <Button
                onClick={() => {
                  const endSplit =
                    values.splits &&
                    values.splits[values.splits.length - 1]?.date
                      ? values.splits[values.splits.length - 1]?.date
                      : timePick.endTime
                  const timeEnd = moment(endSplit)
                    .format()
                    .split('+')[0]
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      checkStatus({
                        variables: {
                          clinicalDepartmentId: isCreateAvailableShift
                            ? selectingClinicDepartmentId
                            : values.clinicalDepartment.id,
                          date: informTime,
                          isHoliday,
                          startTime: timePick.startTime,
                          endTime: timeEnd,
                        },
                      }).then(data => {
                        const isValidTimeRange =
                          data.data
                            .staffCheckAvailableShiftCreateInClinicWorkTime
                        if (!isValidTimeRange) {
                          isCreateAvailableShift
                            ? setOnSubmitCreateAvailableShiftMessage(
                                i18n.t(
                                  'staff.workSchedule.submitWorkScheduleConfirmation'
                                )
                              )
                            : setOnSubmitCreateAvailableShiftMessage(
                                i18n.t(
                                  'staff.workSchedule.submitTimeConfirmation'
                                )
                              )
                        } else {
                          setOnSubmitCreateAvailableShiftMessage(
                            confirmationMessage
                          )
                        }
                        setFieldValue('confirm', true)
                      })
                    } else {
                      setTouched(response)
                    }
                  })
                }}
                label={submitLabel}
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={onSubmitCreateAvailableShiftMessage}
              isCreateAvailableShift={isCreateAvailableShift}
            />
            <Modal
              onCancel={() => setShowModalDoctorNotValid(false)}
              visible={showModalDoctorNotValid}
              title={''}
              centered
              closable={false}
              footer={
                <div style={{textAlign: 'center'}}>
                  <Button className='outline-red'
                          onClick={() => setShowModalDoctorNotValid(false)} label={'閉じる'}/>
                </div>}
            >
              <span  style={{color: '#f84e51', fontWeight: '600'}}>
                こちらの医師は最終勤務日から18ヶ月以上経過しているため、勤務前にオリエンテーションの必要があります。
              </span>
            </Modal>
          </div>
        )
      }}
    />
  )
}

export default ShiftForm
