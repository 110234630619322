import gql from 'graphql-tag'

export const GET_DECLARATION_CHANGE_HISTORY_STATUS = gql`
  query EnumValues {
    enumValues(name: "DeclarationChangeHistoryStatus") {
      value
      description
    }
  }
`

export const CHECK_DOCTOR_ID_EXISTS = gql`
  mutation checkDoctorIdExist($doctorId: ID!) {
    checkDoctorIdExist(doctorId: $doctorId) {
      errorMessage
      checked
    }
  }`

export const CREATE_DECLARATION_CHANGE_HISTORY = gql`
  mutation StaffCreateDeclarationChangeHistory($data: [CreateDeclarationChangeHistoryInput]) {
    staffCreateDeclarationChangeHistory(data: $data)
  }
`