import React from 'react';
import BreadCrumb from "../../../components/Breadcrumb";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import moment from 'moment';
import Calendar from "../../../components/Calendar";
import {Paginator} from "primereact/paginator";
import {ProgressSpinner} from "../../../components";
import Router from "../../../routes/router";
import {getDeclarationChangeHistoryStatus} from "../../../libs";

const StaffDeclarationChangeHistoryScene = (
    {
        i18n,
        popup,
        doctorNameFilter,
        setDoctorNameFilter,
        locationNameFilter,
        setLocationNameFilter,
        workDayFilter,
        setWorkDayFilter,
        data,
        onPageChange,
        setOrderBy,
        setFirst,
        setSkip,
        first,
        skip,
        orderBy,
        handleSearch,
        loading,
        history,
        isViewStaffDeclarationChangeHistory,
        handleClearSearch
    }) => {

    const onDayChange = e => {
        const chosenMonth = moment(e.value)
        setWorkDayFilter(chosenMonth);
    }

    return (<React.Fragment>
        <div className='staff-header'>
            <div className='staff-title'>
                届出事項変更届出作業履歴
                <BreadCrumb items={[{title: '届出事項変更届出作業履歴'}]}/>
            </div>
            <div className='staff-buttons'>
                <Button
                    icon='pi pi-download'
                    className='p-button-normal'
                    onClick={() => history.push({
                        pathname: Router.get(Router.staffWorkHistoryUpload)
                    })}
                    label={'作業履歴CSVアップロード'}
                />
            </div>
        </div>
        <div className={'box is-max'}
             style={{
                 backgroundColor: '#ffffff',
                 marginLeft: '20px',
                 marginRight: '20px',
                 padding: '20px',
                 borderRadius: '10px',
             }}>
            <div style={{gap: '20px'}} className='search-parent'>
                <InputText
                    value={doctorNameFilter}
                    name='doctorName'
                    placeholder={'医師名'}
                    onChange={e => {
                        setDoctorNameFilter(e.target.value)
                    }}
                    style={{width: '170px'}}
                />
                <InputText
                    value={locationNameFilter}
                    name='locationName'
                    placeholder={'拠点名'}
                    onChange={e => {
                        setLocationNameFilter(e.target.value)
                    }}
                    style={{width: '170px'}}
                />
                <div style={{width: '211px'}}>
                    <Calendar
                        name={'workDay'}
                        value={workDayFilter.toDate()}
                        onChange={onDayChange}
                        showIcon
                        readOnlyInput
                        monthNavigator
                        maxDate={moment().toDate()}
                    />
                </div>
                <Button
                    onClick={handleSearch}
                    label={i18n.t('main.movingExpenses.search')}
                    icon='pi pi-search'
                />
                {
                    (locationNameFilter || doctorNameFilter || !workDayFilter.isSame(moment(), "day")) &&
                    <Button
                        icon='pi pi-times'
                        onClick={handleClearSearch}
                        className='p-button-secondary'
                    />
                }
            </div>
            <div>
                {loading && <div style={{height: '300px'}}>
                    <ProgressSpinner/>
                </div>}
                {!loading && data && <table className='table-moving-expenses'>
                    <thead>
                    <tr>
                        <th width='15%'>拠点名</th>
                        <th width='18%'>医師名</th>
                        <th width='15%'>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    width: '100%',
                                    justifyContent: 'center',
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    console.log(123)
                                    if (orderBy === 'workDay_DESC') {
                                        setOrderBy(null)
                                    }
                                    if (orderBy === 'workDay_ASC') {
                                        setOrderBy('workDay_DESC')
                                    }
                                    if (!orderBy || orderBy === 'createdAt_DESC' || orderBy === 'createdAt_ASC') {
                                        setOrderBy('workDay_ASC')
                                    }
                                }}>
                                作業日
                                {orderBy === 'workDay_ASC' &&
                                    <i className='pi pi-sort-up'/>}
                                {orderBy === 'workDay_DESC' &&
                                    <i className='pi pi-sort-down'/>}
                            </div>
                        </th>
                        <th width='12%'>
                            ステータス
                        </th>
                        <th width='15%'>申し送り事項</th>
                        <th width='10%'>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    width: '100%',
                                    justifyContent: 'center',
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (orderBy === 'createdAt_DESC') {
                                        setOrderBy(null)
                                    }
                                    if (orderBy === 'createdAt_ASC') {
                                        setOrderBy('createdAt_DESC')
                                    }
                                    if (!orderBy || orderBy === 'workDay_DESC' || orderBy === 'workDay_ASC') {
                                        setOrderBy('createdAt_ASC')
                                    }
                                }}
                            >
                                登録日
                                {orderBy === 'createdAt_ASC' &&
                                    <i className='pi pi-sort-up'/>}
                                {orderBy === 'createdAt_DESC' &&
                                    <i className='pi pi-sort-down'/>}
                            </div>
                        </th>
                        <th width='14%'>登録者</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.getDeclarationChangeHistories.data.length > 0 && data.getDeclarationChangeHistories.data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.Clinic.name}</td>
                            <td>{item.Doctor.fullName}</td>
                            <td>{moment(item.workDay).format('YYYY年MM月DD日')}</td>
                            <td>{getDeclarationChangeHistoryStatus(item.workStatus)}</td>
                            <td>{item.notes}</td>
                            <td>{moment(item.createdAt).format('YYYY年MM月DD日')}</td>
                            <td>{item.Staff_DeclarationChangeHistory_createdByToStaff.lastname}{item.Staff_DeclarationChangeHistory_createdByToStaff.firstname}</td>
                        </tr>))}
                    </tbody>
                </table>}
            </div>
            {!loading && data && <Paginator
                rows={first}
                totalRecords={data.getDeclarationChangeHistories.totalCount}
                first={skip}
                onPageChange={onPageChange}
                leftContent={<div className='paginator-total'>
                    {i18n.t('main.paginationTotal')}: {data.getDeclarationChangeHistories.totalCount}
                </div>}
            />}
        </div>
    </React.Fragment>);
};

export default StaffDeclarationChangeHistoryScene;