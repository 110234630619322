import React, {useEffect, useState} from 'react';
import StaffDeclarationChangeHistoryScene from "./StaffDeclarationChangeHistoryScene";
import {useCustom} from "../../../provider/context";
import moment from "moment/moment";
import {useQuery} from "@apollo/react-hooks";
import {DECLARATION_CHANGE_HISTORIES_CONNECTION} from "./StaffDeclarationChangeHistory.grapql";
import {ErrorComponent, ProgressSpinner} from "../../../components";
import {checkStaffPermission} from "../../../libs";
import {STAFF_VIEW_PAGE_DECLARATION_CHANGE_HISTORY} from "../../../constant/permissions";

const StaffDeclarationChangeHistory = ({
                                           history,
                                           location: {search},
                                           progress,
                                           setProgress,
                                           removeProgress,
                                       }) => {
    const [{i18n, popup}] = useCustom();
    const isViewStaffDeclarationChangeHistory = checkStaffPermission({
        functionName: STAFF_VIEW_PAGE_DECLARATION_CHANGE_HISTORY
    })
    const [doctorNameFilter, setDoctorNameFilter] = useState('');
    const [locationNameFilter, setLocationNameFilter] = useState('');
    const [workDayFilter, setWorkDayFilter] = useState(moment());
    const [currentDate, setCurrentDate] = useState(moment());
    const [orderBy, setOrderBy] = useState('createdAt_DESC')
    const [first, setFirst] = useState(20)
    const [skip, setSkip] = useState(0);
    const [clearSearch, setClearSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const {loading, error, data, refetch} = useQuery(DECLARATION_CHANGE_HISTORIES_CONNECTION, {
        variables: {
            first: first < 0 ? 20 : first,
            skip: skip < 0 ? 0 : skip,
            orderBy: orderBy,
            workDay: currentDate
        }
    })

    useEffect(() => {
        if (clearSearch) {
            void handleSearch();
            setClearSearch(false);
        }
    }, [clearSearch])

    useEffect(() => {
        if (isSearch) {
            void handleSearch();
            setIsSearch(false);
        }
    }, [isSearch]);

    const handleSearch = async () => {
        await refetch({
            first: first < 0 ? 20 : first,
            skip: skip < 0 ? 0 : skip,
            orderBy: orderBy,
            doctorName: doctorNameFilter,
            locationName: locationNameFilter,
            workDay: workDayFilter
        });
    }

    const onPageChange = (history) => {
        const newPage = history.page + 1;
        const newSkip = (newPage - 1) * first;
        setSkip(newSkip);
        setIsSearch(true);
    }
    const handleClearSearch = () => {
        setDoctorNameFilter('');
        setLocationNameFilter('');
        setWorkDayFilter(moment());
        setOrderBy(null);
        setFirst(20);
        setSkip(0);
        setClearSearch(true);
    }

    if (error) return <ErrorComponent error={error}/>

    return (
        <StaffDeclarationChangeHistoryScene
            history={history}
            loading={loading}
            i18n={i18n}
            popup={popup}
            doctorNameFilter={doctorNameFilter}
            setDoctorNameFilter={setDoctorNameFilter}
            locationNameFilter={locationNameFilter}
            setLocationNameFilter={setLocationNameFilter}
            workDayFilter={workDayFilter}
            setWorkDayFilter={setWorkDayFilter}
            data={data}
            handleSearch={handleSearch}
            onPageChange={onPageChange}
            setOrderBy={setOrderBy}
            setFirst={setFirst}
            setSkip={setSkip}
            first={first}
            skip={skip}
            orderBy={orderBy}
            isViewStaffDeclarationChangeHistory={isViewStaffDeclarationChangeHistory}
            handleClearSearch={handleClearSearch}
        />
    );
};

export default StaffDeclarationChangeHistory;