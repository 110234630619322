import React, {useEffect, useState} from 'react';
import {useCustom} from "../../../provider/context";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CLINICS_CONNECTION} from "../StaffDoctorOutputInsurance/StaffDoctorOutputInsurance.graphql";
import Auth from "../../../middleware/auth";
import {checkStaffPermission} from "../../../libs";
import {STAFF_CHECK_SHIFT, STAFF_UPLOAD_SHIFT} from "../../../constant/permissions";
import {ErrorComponent, ProgressSpinner} from "../../../components";
import styles from "../ExpectationDoubleRecruitmentRecord/Style";
import StaffWorkHistoryUploadScene from "./StaffWorkHistoryUploadScene";
import {
  CHECK_DOCTOR_ID_EXISTS,
  CREATE_DECLARATION_CHANGE_HISTORY,
  GET_DECLARATION_CHANGE_HISTORY_STATUS
} from "./StaffWorkHistoryUpload.graphql";
import {useHistory} from "react-router-dom";
import Router from "../../../routes/router";
import moment from "moment/moment";

const StaffWorkHistoryUpload = () => {
  const history = useHistory();
  const [{i18n, popup}] = useCustom();
  const {loading, error, data} = useQuery(CLINICS_CONNECTION, {
    fetchPolicy: 'network-only',
  });
  const {data: declarationChangeHistoryStatus} = useQuery(GET_DECLARATION_CHANGE_HISTORY_STATUS);
  const [checkDoctorIdMutation] = useMutation(CHECK_DOCTOR_ID_EXISTS);
  const [staffCreateDeclarationChangeHistory] = useMutation(CREATE_DECLARATION_CHANGE_HISTORY);
  const token = Auth.getToken();
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT,
  });
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_SHIFT,
  });
  const [state, setState] = useState({
    isUploaded: false,
  });
  const [stepEdit, setStepEdit] = useState(false);
  const [editableRows, setEditableRows] = useState({});

  useEffect(() => {
    if (state.isUploaded) {
      document.getElementsByClassName('staff-layout-body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByClassName('staff-layout-body')[0].style.overflowY = 'scroll';
    }
  }, [state.isUploaded]);

  if (loading) return <ProgressSpinner/>
  if (error) return <ErrorComponent error={error}/>

  const {
    clinicsConnection: {
      edges,
    },
  } = data

  const convertClinic = (clinics) => (clinics.map(clinic => {
    return {
      ...clinic.node,
    }
  }))

  const checkCsvData = async ({clinic_id}, {data}, {setFieldValue}) => {
    setState({
      isUploaded: true,
    })
    try {
      if (!isUploadCheckPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        const messageAddedCsvData = await Promise.all(
          data.map(async (item) => {
            if (item.clinic_id !== clinic_id) {
              setFieldValue('validate', false)
              return {
                ...item,
                errorMessage: 'クリニックが一致しません。',
                checked: true,
              }
            }
            const {data: {checkDoctorIdExist}} = await checkDoctorIdMutation({
              variables: {
                doctorId: item.doctor_id,
              }
            })
            if (checkDoctorIdExist.errorMessage) {
              setFieldValue('validate', false)
              return {
                ...item,
                errorMessage: checkDoctorIdExist.errorMessage,
                checked: checkDoctorIdExist.checked,
              }
            }
            setFieldValue('validate', true)
            return {
              ...item,
              checked: true,
            }
          }))
        setFieldValue('data', messageAddedCsvData)
        setState({
          isUploaded: false,
        })
        setTimeout(() => {
          popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      popup.error(error)
      setState({
        isUploaded: false,
      })
    }
  }

  const downloadSample = () => {
    window.location.href = `${
      process.env.REACT_APP_ENDPOINT
    }/files/作業履歴アップロードCSVサンプル.csv?token=${token}`
  }

  const onSubmit = async ({data}, {resetForm}) => {
    try {
      const response = await staffCreateDeclarationChangeHistory({
        variables: {
          data: data.map(item => ({
            workDay: moment(item.workDayType),
            workStatus: item.workStatusType,
            clinic_id: item.clinic_id,
            doctor_id: item.doctor_id,
            notes: item.notes,
          }))
        }
      })
      console.log(response)
      if (response.data.staffCreateDeclarationChangeHistory) {
        popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'));
        resetForm();
        history.push(Router.staffDeclarationChangeHistory)
      }
    } catch (err) {
      console.error(error)
      popup.clear()
      popup.error(error)
    }

  }
  return (
    <>
      {
        state.isUploaded && <div style={styles.loading}>
          <ProgressSpinner/>
        </div>
      }
      <StaffWorkHistoryUploadScene
        isUploaded={state.isUploaded}
        popup={popup}
        onSubmit={onSubmit}
        i18n={i18n}
        convertClinic={convertClinic}
        edges={edges}
        downloadSample={downloadSample}
        isUploadPermitted={isUploadPermitted}
        isUploadCheckPermitted={isUploadCheckPermitted}
        checkCsvData={checkCsvData}
        declarationChangeHistoryStatus={declarationChangeHistoryStatus}
        setStepEdit={setStepEdit}
        stepEdit={stepEdit}
        editableRows={editableRows}
        setEditableRows={setEditableRows}

      />
    </>
  );
};

export default StaffWorkHistoryUpload;