export const getDeclarationChangeHistoryStatus = status => {
  let name = ''
  switch (status) {
    case 'APPLIED_FOR_EMPLOYMENT':
      name = '入社申請済み'
      break
    case 'APPLIED_FOR_RETIREMENT':
      name = '退職申請済み'
      break
    case 'PENDING':
      name = '保留'
      break
    default:
      break
  }
  return name
}