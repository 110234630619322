import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { DOCTOR_EMAIL } from '@medical/constant'
import { clinicalDepartmentNames, cloudWatchLogger } from '@medical/libs'
import middleware from '@medical/middleware'
import { useCustom } from '@medical/provider/context'
import { removeItem } from '@medical/provider/store/reducers/cart/cart.action'
import React, {useState} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { checkOverlapTime } from '@medical/libs/checkOverlapTime'
import {
  DEPARTMENTS_LIST,
  SEND_REQUESTED_SCHEDULE_MAIL,
  WORK_SCHEDULE_CREATE,
} from './DoctorCart.graphql'
import DoctorCartScene from './DoctorCartScene'
import { DOCTOR_WORK_SCHEDULES } from '../FindAvailableShift/FindAvailableShiftsence.graphql'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'

const DoctorCart = ({ cartItems, removeItemFromCart }) => {
  const [{ i18n, popup }] = useCustom()
  const [createWorkSchedule] = useMutation(WORK_SCHEDULE_CREATE)
  const [sendRequestedMail] = useMutation(SEND_REQUESTED_SCHEDULE_MAIL)
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: doctorMeLoading,
    error: doctorMeError,
    data: doctorMeData,
  } = useQuery(DOCTOR_ME)
  const {
    loading: loadingWorkSchedules,
    error: errorWorkSchedules,
    data: dataWorkSchedules,
  } = useQuery(DOCTOR_WORK_SCHEDULES, {
    variables: {
      where: {
        id: doctorMeData.doctorMe && doctorMeData.doctorMe.id,
      },
      workSchedulesWhere2: {
        accepted: true,
        deletedAt: null,
        AvailableShift: {
          isPublished: true,
        },
        startTime_gte: moment()
          .startOf('day')
          .toISOString(),
      },
    },
    fetchPolicy: 'network-only',
  })
  const workSchedules = []
  if (dataWorkSchedules?.doctor) {
    workSchedules.push(...dataWorkSchedules.doctor?.workSchedules)
  }
  const checkWorkScheduleExits = item => {
    let isExits = false
    for (let index = 0; index < workSchedules.length; index++) {
      if (
        checkOverlapTime(
          workSchedules[index].startTime,
          workSchedules[index].endTime,
          item.startTime,
          item.endTime
        )
      ) {
        isExits = true
        break
      }
    }

    return isExits
  }
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const requestedAvailableShiftIds = []
    let email
    if (middleware.isDoctor()) {
      email = localStorage.getItem(DOCTOR_EMAIL)
    }
    for (let i = 0; i < values.items.length; i += 1) {
      const { id: availableShiftId, doctorComment } = values.items[i]
      if (checkWorkScheduleExits(values.items[i])) {
        popup.error('同日時で確定シフトがあるためカートに追加できません')
        setSubmitting(false)
        resetForm()
      } else if (moment(values.items[i].startTime).isBefore(moment())) {
        popup.error('このシフトの開始時間が過ぎましたので応募できません。')
        setSubmitting(false)
        resetForm()
      } else {
        try {
          await createWorkSchedule({
            variables: {
              availableShiftId,
              doctorComment,
            },
          })
          requestedAvailableShiftIds.push(availableShiftId)
          removeItemFromCart(availableShiftId)
          popup.success(i18n.t('doctor.cart.submissionSuccess'))
        } catch (error) {
          removeItemFromCart(availableShiftId)
          popup.error(error)
        }
      }
    }
    if (requestedAvailableShiftIds.length > 0) {
      try {
        await sendRequestedMail({
          variables: {
            requestedAvailableShiftIds,
          },
        })
      } catch (error) {
        cloudWatchLogger().error({
          level: 'ERROR',
          detail: 'SENDING_REQUESTED_EMAIL_FAILED',
          email,
          urlError: window.location.href,
        })
      }
    }
    setSubmitting(false)
    resetForm()
  }

  if (loading || doctorMeLoading || loadingWorkSchedules)
    return <ProgressSpinner />
  if (error || doctorMeError || errorWorkSchedules)
    return <ErrorComponent error={error} />

  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })

  return (
    <DoctorCartScene
      cartItems={cartItems}
      i18n={i18n}
      removeItemFromCart={removeItemFromCart}
      onSubmit={onSubmit}
      departmentNames={departmentNames}
      lastWorkday={doctorMeData?.doctorMe?.countedDoctorLastWorkShift?.lastWorkday}
    />
  )
}

const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DoctorCart)
