import React from 'react';
import * as Yup from "yup";
import BreadCrumb from "../../../components/Breadcrumb";
import Router from "../../../routes/router";
import Calendar from "../../../components/Calendar";
import moment from "moment/moment";
import {FieldArray, Formik} from "formik";
import AutoComplete from "../../../components/AutoComplete";
import {Button} from "primereact/button";
import Papa from "papaparse";
import Dropzone from "react-dropzone";
import {getDeclarationChangeHistoryStatus} from "../../../libs";
import {Input} from "antd";
import {Select} from 'antd';

const StaffWorkHistoryUploadScene = (
    {
        i18n,
        popup,
        edges,
        convertClinic,
        downloadSample,
        isUploadPermitted,
        isUploadCheckPermitted,
        checkCsvData,
        isUploaded,
        onSubmit,
        declarationChangeHistoryStatus,
        setStepEdit,
        stepEdit,
        editableRows,
        setEditableRows
    }
) => {
    const translateKeys = {
        clinic_id: 'clinic_id',
        doctor_id: 'doctor_id',
        workDay: '作業日',
        workStatus: 'ステータス',
        notes: '申し送り事項'
    }

    const validationSchema = Yup.object().shape({
        data: Yup.array()
            .min(1, i18n.t('validation.atLeastOneItem'))
            .of(
                Yup.object().shape({
                    clinic_id: Yup.string()
                        .matches(/^[a-zA-Z0-9]+$/, '正しい値を入力してください')
                        .typeError('正しい値を入力してください')
                        .required('clinic_idを入力してください。'),
                    doctor_id: Yup.string()
                        .matches(/^[a-zA-Z0-9]+$/, '正しい値を入力してください')
                        .typeError('正しい値を入力してください')
                        .required('doctor_idを入力してください'),
                })
            )
    })

    const exceptKeys = ['checked', 'errorMessage']

    const handleEditToggle = (index) => {
        setEditableRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const handleInputChange = (index, field, value, setFieldValue, data) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        setFieldValue('data', updatedData);
    };
    return (
        <>
            <div className={'staff-header'}>
                <div className={'staff-title'}>
                    {
                        !stepEdit ? '作業履歴アップロード' : '登録内容確認'
                    }
                    <BreadCrumb
                        items={[
                            {
                                title: '届出事項変更届出作業履歴',
                                to: Router.get(Router.staffDeclarationChangeHistory)
                            },
                            {
                                title: '作業履歴アップロード',
                                ...(stepEdit && {to: Router.get(Router.staffWorkHistoryUpload)})
                            },
                            (stepEdit && {title: '登録内容確認'})
                        ]}
                    />
                </div>
                <div className='staff-buttons'>
                    <Button
                        icon='pi pi-download'
                        className='p-button-normal'
                        onClick={downloadSample}
                        label={i18n.t('main.sampleCsv')}
                    />
                </div>
            </div>
            <div className='container is-max'>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        data: [],
                        declarationChangeHistoryStatus: declarationChangeHistoryStatus && declarationChangeHistoryStatus.enumValues,
                        clinics: convertClinic(edges),
                        clinic_id: '',
                        workStatus: '',
                        disabled: false,
                        clinic_name: '',
                        validate: false,
                        workDay: moment()
                    }}
                    validationSchema={validationSchema}
                    render={formikProps => {
                        const {
                            values,
                            errors,
                            setFieldValue,
                            handleSubmit,
                            resetForm,
                            touched,
                        } = formikProps
                        const {data} = values
                        let keys = []
                        if (data.length > 0) {
                            keys = Object.keys(data[0]).filter(
                                key => key !== 'errorMessage' || key !== 'checked'
                            )
                        }
                        if (!stepEdit) {
                            return (
                                <>
                                    {
                                        isUploadPermitted || isUploadCheckPermitted ? (
                                            <div className={'box'}>
                                                <div className={'box-top'} style={{alignItems: 'end'}}>
                                                    <div
                                                        style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                                        <Calendar
                                                            style={{width: '300px'}}
                                                            name={'workDay'}
                                                            value={values.workDay.toDate()}
                                                            onChange={(e) => {
                                                                const chosenMonth = moment(e.value)
                                                                setFieldValue('workDay', chosenMonth)
                                                            }}
                                                            showIcon
                                                            readOnlyInput
                                                            monthNavigator
                                                            maxDate={moment().toDate()}
                                                        />
                                                        <AutoComplete
                                                            disabled={values.disabled}
                                                            {...formikProps}
                                                            name={'clinic'}
                                                            field='name'
                                                            searchFields={['name']}
                                                            allSuggestions={values.clinics}
                                                            suggestionName='clinics'
                                                            width='60vw'
                                                            maxWidth='300px'
                                                            placeholder='クリニック'
                                                            setCurrentClinicData={
                                                                async val => {
                                                                    setFieldValue('clinic_id', val.id)
                                                                    setFieldValue('clinic_name', val.name)
                                                                    setFieldValue('clinics', convertClinic(edges))
                                                                }
                                                            }
                                                        />
                                                        <AutoComplete
                                                            disabled={values.disabled}
                                                            {...formikProps}
                                                            name={'value'}
                                                            field='description'
                                                            searchFields={['description']}
                                                            allSuggestions={values.declarationChangeHistoryStatus}
                                                            suggestionName='description'
                                                            width='60vw'
                                                            maxWidth='300px'
                                                            placeholder='作業ステータス'
                                                            setCurrentClinicData={
                                                                async val => {
                                                                    setFieldValue('workStatus', val.value)
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                    <div className={'doctor__output-action'}>
                                                        {data.length > 0 && (
                                                            <Button
                                                                type={'button'}
                                                                onClick={() => {
                                                                    setFieldValue('data', [])
                                                                    setFieldValue('disabled', false)
                                                                }}
                                                                label={i18n.t('main.cancel')}
                                                                className='p-button-secondary'
                                                                icon='pi pi-times'
                                                            />
                                                        )}
                                                        {
                                                            isUploadCheckPermitted ? (
                                                                <Button
                                                                    disabled={data.length === 0 || isUploaded || !values.clinic_id}
                                                                    type={'button'}
                                                                    label={i18n.t('staff.csvUpload.check')}
                                                                    onClick={() => {
                                                                        if (
                                                                            (errors.data && errors.data.length > 0) ||
                                                                            values.data.find(d => d.errorMessage)
                                                                        ) {
                                                                            popup.error(i18n.t('main.hasCsvFileError'))
                                                                        } else {
                                                                            checkCsvData({clinic_id: values.clinic_id}, {data: values.data}, {setFieldValue})
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null
                                                        }
                                                        {
                                                            isUploadPermitted ? (
                                                                <Button
                                                                    onClick={() => {
                                                                        if (
                                                                            (errors.data && errors.data.length > 0) ||
                                                                            values.data.find(d => d.errorMessage)
                                                                        ) {
                                                                            popup.error(i18n.t('main.hasCsvFileError'))
                                                                        } else {
                                                                            setStepEdit(true)
                                                                        }
                                                                    }}
                                                                    disabled={data.length === 0 || isUploaded || !values.clinic_id || !values.validate}
                                                                    type={'button'}
                                                                    label={'変換する'}
                                                                />
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    data.length < 1 ? <Dropzone
                                                            disabled={!values.clinic_id || !values.workDay || !values.workStatus || isUploaded}
                                                            onDrop={files => {
                                                                if (files.length > 0) {
                                                                    Papa.parse(files[0], {
                                                                        header: true,
                                                                        complete: async results => {
                                                                            const {meta, data, errors} = results
                                                                            const renderData = data.map(obj => {
                                                                                let cleanedObj = {};
                                                                                for (let key in obj) {
                                                                                    let trimmedKey = key.trim();
                                                                                    let trimmedValue = obj[key].trim();
                                                                                    if (trimmedKey && trimmedValue && (trimmedKey === 'clinic_id' || trimmedKey === 'doctor_id' || trimmedKey === 'notes')) {
                                                                                        cleanedObj[trimmedKey] = trimmedValue;
                                                                                    }
                                                                                }
                                                                                cleanedObj['workDay'] = moment(values.workDay).format('YYYY年MM月DD日');
                                                                                cleanedObj['workStatus'] = getDeclarationChangeHistoryStatus(values.workStatus);
                                                                                cleanedObj['workStatusType'] = values.workStatus
                                                                                cleanedObj['workDayType'] = moment(values.workDay).toISOString();
                                                                                return cleanedObj;
                                                                            }).filter(obj => {
                                                                                return Object.keys(obj).length > 0 && Object.entries(obj).some(([key, value]) => {
                                                                                    return key.trim() && value.trim();
                                                                                });
                                                                            });
                                                                            setFieldValue('data', renderData)
                                                                            setFieldValue('disabled', true)
                                                                        },
                                                                    })
                                                                }
                                                            }}
                                                            onError={error => console.log(error)}
                                                            accept='.csv'
                                                        >
                                                            {({getRootProps, getInputProps}) => (
                                                                <div {...getRootProps({className: 'dropzone'})}>
                                                                    <input {...getInputProps()} />
                                                                    <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                                                                </div>
                                                            )}
                                                        </Dropzone> :
                                                        <div className='overflow-container'>
                                                            <table className='table'>
                                                                <thead>
                                                                <tr>
                                                                    <th>{i18n.t('main.action')}</th>
                                                                    {keys.map(
                                                                        (key, index) =>
                                                                            !exceptKeys.includes(key) && key !== 'workStatusType' && key !== 'workDayType' && (
                                                                                <th key={`${key}_${index}`}>
                                                                                    {translateKeys[key]}
                                                                                </th>
                                                                            )
                                                                    )}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <FieldArray
                                                                    name={'data'}
                                                                    render={({remove}) => (
                                                                        <>
                                                                            {data.map((item, i) => {
                                                                                const error =
                                                                                    errors.data && errors.data.length > 0
                                                                                        ? errors.data[i]
                                                                                        : false
                                                                                const isChecked = data[i].checked
                                                                                const errorMessage =
                                                                                    item.errorMessage ||
                                                                                    Object.keys(error || {}).reduce(
                                                                                        (acc, curr) => {
                                                                                            return `${acc}\n${error[curr]}`
                                                                                        },
                                                                                        ''
                                                                                    )
                                                                                return (
                                                                                    <React.Fragment key={i}>
                                                                                        <tr>
                                                                                            {!errorMessage ? (
                                                                                                <td>
                                                                                                    {isChecked ? (
                                                                                                        <Button
                                                                                                            icon='pi pi-check'
                                                                                                            className='p-button-success'
                                                                                                            tooltip={'OK'}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Button
                                                                                                            icon='pi pi-info'
                                                                                                            tooltip={i18n.t(
                                                                                                                'staff.csvUpload.notChecked'
                                                                                                            )}
                                                                                                        />
                                                                                                    )}
                                                                                                </td>
                                                                                            ) : (
                                                                                                <td>
                                                                                                    <div>
                                                                                                        <Button
                                                                                                            onClick={() => remove(i)}
                                                                                                            icon='pi pi-times'
                                                                                                            className='p-button-danger'
                                                                                                        />
                                                                                                    </div>
                                                                                                </td>
                                                                                            )}
                                                                                            {keys.map(
                                                                                                key =>
                                                                                                    !exceptKeys.includes(key) && key !== 'workStatusType' && key !== 'workDayType' && (
                                                                                                        <td key={key}>{item[key]}</td>
                                                                                                    )
                                                                                            )}
                                                                                        </tr>
                                                                                        {errorMessage && (
                                                                                            <tr key={`errorMessage_${i}`}>
                                                                                                <td colSpan={keys.length + 1}>
                                                                                                    <div
                                                                                                        className='alert is-left'>
                                                                                                        {errorMessage}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    )}
                                                                />
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                }
                                            </div>
                                        ) : null
                                    }
                                </>
                            )
                        }
                        return (
                            <div className={'box'}>
                                <div>
                                    <table className='table'>
                                        <thead>
                                        <tr>
                                            {keys.map(
                                                (key, index) =>
                                                    !exceptKeys.includes(key) && key !== 'workStatusType' && key !== 'workDayType' && (
                                                        <th key={`${key}_${index}`}>
                                                            {translateKeys[key]}
                                                        </th>
                                                    )
                                            )}
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.length > 0 && data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.clinic_id}</td>
                                                    <td>{item.doctor_id}</td>
                                                    <td>
                                                        {
                                                            editableRows[index] ?
                                                                (<Input type={'text'} value={item.notes}
                                                                        onChange={(e) => handleInputChange(index, 'notes', e.target.value, setFieldValue, data)}/>) :
                                                                item.notes
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            editableRows[index] ?
                                                                <Calendar
                                                                    name={`workDay`}
                                                                    value={moment(item.workDayType)}
                                                                    onChange={(e) => {
                                                                        const chosenMonth = moment(e.value);
                                                                        handleInputChange(index, 'workDayType', chosenMonth, setFieldValue, data)
                                                                    }}
                                                                    showIcon
                                                                    readOnlyInput
                                                                    monthNavigator
                                                                    maxDate={moment().toDate()}
                                                                />
                                                                : item.workDay
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            editableRows[index] ?
                                                                <Select
                                                                    style={{width: 200}}
                                                                    defaultValue={item.workStatusType}
                                                                    onChange={(e) => handleInputChange(index, 'workStatusType', e, setFieldValue, data)}
                                                                    options={values.declarationChangeHistoryStatus.map(item => {
                                                                        return {
                                                                            label: item.description,
                                                                            value: item.value
                                                                        }
                                                                    })}
                                                                /> :
                                                                item.workStatus
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            editableRows[index] ?
                                                                null :
                                                                <Button
                                                                    icon='pi pi-pencil'
                                                                    onClick={() => handleEditToggle(index)}
                                                                />
                                                        }

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    marginTop: '20px'
                                }}>
                                    <Button
                                        onClick={() => {
                                            setStepEdit(false);
                                            resetForm();
                                        }}
                                        type={'button'}
                                        label={'キャンセル'}/>
                                    <Button onClick={() => handleSubmit()} type={'button'} label={'登録する'}/>
                                </div>
                            </div>
                        )
                    }}
                />
                <div style={{width: '211px'}}>

                </div>
            </div>
        </>
    );
};

export default StaffWorkHistoryUploadScene;