import ClinicNameRender from '@medical/components/ClinicNameRender'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import InputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import { FORMAT_TIME_NOT_UTC, objectTargetConstant } from '@medical/constant'
import { convertDoctorsConnection, splitLogicReverse } from '@medical/libs'
import { validateStartTimeEndTime } from '@medical/constant/utilities'
import ShiftFormSplits from '@medical/pages/staff/components/ShiftFormSplits'
import {Checkbox, Switch, DatePicker, TimePicker, Modal} from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Editor from '@medical/components/Editor'
import { ShiftFormDoctorPicker } from '../components/ShiftFormDoctorPicker'
import { ShiftFormRecruitmentCategoryDetail } from '../components/ShiftFormRecruitmentCategoryDetail'
import { WorkScheduleComment } from '../DetailWorkSchedule/WorkScheduleComment'
import { DOCTORS_CONNECTION } from './DetailAvailableShift.graphql'
import DetailAvailableShiftTemplateMail from './DetailAvailableShiftTemplateMail'
import Router from '@medical/routes/router'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/ja_JP'

const DetailAvailableShiftScene = ({
  i18n,
  availableShift,
  onSubmit,
  doctors,
  update,
  remove,
  connectToDoctor,
  isMoneyPermitted,
  isUpdatePermitted,
  isDeletePermitted,
  isCreateWsPermitted,
  departmentNames,
  activityHistories,
  updateHistories,
  isChangeRecruitmentShiftsStatusPermitted,
  popup,
  isPeriodical,
  staffComments,
  isDeleteCommentPermitted,
  staffMe,
  availableShiftId,
  isAddCommentPermitted,
  staffCommentAvailableShiftGroup,
  convertDoctors,
  client,
  doctorsList,
  getNoDoctorWorked,
  countDoctorWorkedInClinic,
  isEditAvailableShiftThePast,
  recruitmentCategory,
  recruitmentCategoryScheduleGroup,
  location,
  history,
  isDeleteAllComment
}) => {
  const [newDoctors, setNewDoctors] = useState(doctors)
  const [timePick, setTimePick] = useState({
    startTime: moment(availableShift.startTime).format(FORMAT_TIME_NOT_UTC),
    endTime: moment(availableShift.endTime).format(FORMAT_TIME_NOT_UTC),
  })
  const [isHideWarningText, setHideWarningText] = useState(false)
  const [showModalDoctorNotValid, setShowModalDoctorNotValid] = useState(false)
  const options = [
    {
      value: 'DEFAULT',
      description: '勤務種別未定',
    },
    {
      value: 'FULL_TIME_SHIFT',
      description: '常勤医師のシフト勤務',
    },
    {
      value: 'FULL_TIME_SHIFT_CHANGE',
      description: '常勤医師のシフト変更勤務',
    },
    {
      value: 'FULL_TIME_SHIFT_EXTRA',
      description: '常勤医師の追加勤務',
    },
    {
      value: 'PART_TIME_SHIFT_REGULAR',
      description: '定期非常勤医師の定期シフト勤務',
    },
    {
      value: 'PART_TIME_SHIFT_CHANGE',
      description: '定期非常勤医師の定期シフト変更勤務',
    },
    {
      value: 'PART_TIME_SHIFT_SPOT_WORK',
      description: '定期非常勤医師のスポット勤務',
    },
    {
      value: 'NON_REGULAR_PART_TIME_SHIFT_SPOT_WORK',
      description: '非定期非常勤医師のスポット勤務',
    },
  ]
  const optionsKnowAboutShift = [
    {
      value: 'DIRECT',
      description: '直接',
    },
    {
      value: 'REFERENCE_COMPANY',
      description: '紹介会社',
    },
    {
      value: 'OTHER',
      description: 'その他',
    },
  ]

  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    return !min.isSameOrBefore(current)
  }

  const disabledHours = time => {
    const result = []
    const timeStamp = moment().format('HH')
    for (let i = 0; i < 24; i += 1) {
      if (i <= timeStamp) {
        result.push(i)
      }
    }
    return result
  }

  const disabledMinute = value => {
    const result = []
    for (let i = 1; i < value; i += 1) {
      result.push(i)
    }
    return result
  }

  const validationSchema = Yup.object().shape({
    date: Yup.date(i18n.t('validation.date.required')).required(
      i18n.t('validation.date.required')
    ),
    startTime: Yup.date('Should be string').required(''),
    splits: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required(i18n.t('validation.date.required')),
        hourlyWage: availableShift.hourlyWage
          ? Yup.number().required(i18n.t('validation.hourlyWage.required'))
          : null,
      })
    ),
    comment: Yup.string().nullable(),
    acceptedReason: Yup.string()
      .nullable()
      .max(4500, i18n.t('validation.max', { max: 4500 })),
    objectTarget: Yup.string().nullable(),
    workPattern: Yup.mixed().when('objectTarget', {
      is: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
      then: Yup.mixed().test(
        'validateWorkPartten',
        i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
        val => val && val.length > 0
      ),
    }),
    specificDoctors: Yup.mixed().when('objectTarget', {
      is: objectTargetConstant.SET_FOR_EACH_DOCTOR,
      then: Yup.mixed().test(
        'validateSelectDoctor',
        i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
        val => val && val.length > 0
      ),
    }),
  })
  const listObjectTarget = [
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachDoctor'
      ),
      value: objectTargetConstant.SET_FOR_EACH_DOCTOR,
    },
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachWorkPartten'
      ),
      value: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
    },
  ]

  const listWorkPartten = [
    {
      value: 'ALL_WORKPATTTEN',
      description: i18n.t('main.ALL_WORK_PATTERN'),
    },
    {
      value: 'PERMANENT_DOCTOR',
      description: i18n.t('main.PERMANENT_DOCTOR'),
    },
    {
      value: 'REGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'IRREGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.IRREGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'TEMPORARY_DOCTOR',
      description: i18n.t('main.TEMPORARY_DOCTOR'),
    },
  ]

  useEffect(() => {
    setHideWarningText(
      validateStartTimeEndTime(timePick.startTime, timePick.endTime)
    )
  }, [timePick])

  return (
    <div>
      <div className={'modal-title'}>
        ステータス：募集シフト
      </div>
      <Formik
        initialValues={{
          clinicalDepartment: availableShift.clinicalDepartment,
          functionName: '',
          confirm: false,
          doctor: '',
          splitComments: [
            {
              text: '',
              type: '',
            },
          ],
          clinicName: ClinicNameRender({
            clinicalDepartment: availableShift.clinicalDepartment,
          }),
          departmentName:
            departmentNames[availableShift.clinicalDepartment.name],
          scheduleDate: moment(availableShift.startTime).format('LL (dd)'),
          date: moment(availableShift.startTime).toDate(),
          startTime: moment(availableShift.startTime).toDate(),
          splits: splitLogicReverse({availableShift}),
          doctors: newDoctors,
          comment: availableShift.comment,
          acceptedReason: '',
          type: '',
          isPublished: availableShift.isPublished,
          isFirstWork: false,
          isSpecial: availableShift.isSpecial,
          introductionHistory: '',
          introductionHistoryOther: '',
          isSetIndividually: availableShift
            ? availableShift.isSetIndividually
            : false,
          isEarlySpecial: availableShift?.isEarlySpecial || false,
          isDoubleRecruitment: availableShift?.isDoubleRecruitment || false,
          objectTarget: availableShift ? availableShift.applyWith : undefined,
          workParttenSearch: '',
          textSearchDoctorByName: '',
          isShowListBox: false,
          specificDoctors:
            availableShift.applyWith ===
            objectTargetConstant.SET_FOR_EACH_DOCTOR &&
            availableShift.availableShiftDetails
              ? convertDoctors(
                availableShift.availableShiftDetails.map(e => e.doctor)
              )
              : [],
          workPattern:
            availableShift.applyWith ===
            objectTargetConstant.SET_FOR_EACH_WORKPARTTEN &&
            availableShift.workPatternApply
              ? JSON.parse(availableShift.workPatternApply)
              : [],
          doctorsList,
          isSendIntroduceMess: false,
          recruitmentCategory: availableShift?.recruitmentCategory
            ? availableShift.recruitmentCategory
            : [],
          isSettingHideShift: !!availableShift.timeSettingHideShift,
          dateSettingHideShift: availableShift.timeSettingHideShift
            ? moment(availableShift.timeSettingHideShift)
            : '',
          timeSettingHideShift: availableShift.timeSettingHideShift
            ? moment(availableShift.timeSettingHideShift)
            : '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            values,
            errors,
            validateForm,
            setFieldValue,
            setFieldError,
            setTouched,
            touched,
            dirty,
            handleSubmit,
            handleChange,
          } = formikProps
          let confirmationMessage
          switch (values.functionName) {
            case update:
              confirmationMessage = i18n.t(
                'staff.availableShift.updateConfirmation'
              )
              break
            case remove:
              confirmationMessage = i18n.t(
                'staff.availableShift.removeConfirmation'
              )
              break
            case connectToDoctor:
              confirmationMessage = i18n.t(
                'staff.availableShift.connectToDoctorConfirmation'
              )
              break
            default:
              break
          }
          const validateAndConfirm = () =>
            validateForm().then(response => {
              if (!Object.keys(response).length) {
                setFieldValue('confirm', true)
              } else {
                setTouched(response)
              }
            })
          return (
            <div>
              {availableShift.name && (
                <div className='tr'>
                  <div className='th'>{i18n.t('main.shiftTitle')}</div>
                  <div className='td' style={{paddingLeft: '20px'}}>
                    {availableShift.name}
                  </div>
                </div>
              )}

              <TextInput
                {...formikProps}
                name='clinicName'
                label={i18n.t('main.clinicName')}
                disabled
              />
              <TextInput
                {...formikProps}
                name='departmentName'
                label={i18n.t('main.departmentName')}
                disabled
              />
              <TextInput
                {...formikProps}
                name='scheduleDate'
                label={i18n.t('main.scheduleDate')}
                disabled
                classDisabled
              />
              <ShiftFormSplits
                {...formikProps}
                i18n={i18n}
                isMoneyPermitted={isMoneyPermitted}
                isDetailAvailableShift
                isHideWarningText={isHideWarningText}
                getStartTimeValue={async (val, index) => {
                  setTimePick({
                    ...timePick,
                    startTime: moment(val)
                      .format()
                      .split('+')[0],
                  })
                }}
                getEndTimeValue={async (val, index) => {
                  setTimePick({
                    ...timePick,
                    endTime: moment(val)
                      .format()
                      .split('+')[0],
                  })
                }}
              />
              {!isMoneyPermitted || !isAddCommentPermitted ? (
                undefined
              ) : (
                <div className='staffpage-comment-container'>
                  <div className='staffpage-title'>
                    {i18n.t('main.staffComment.titleStaffComment')}
                  </div>
                  <WorkScheduleComment
                    comments={staffComments}
                    staffId={staffMe.id}
                    isDeletePermitted={isDeleteCommentPermitted}
                    availableShiftId={availableShiftId}
                    isMoneyPermitted={isMoneyPermitted}
                    isAddPermitted={isAddCommentPermitted}
                    staffCommentAvailableShiftGroup={
                      staffCommentAvailableShiftGroup
                    }
                    isDeleteAllComment={isDeleteAllComment}
                  />
                </div>
              )}
              {/* Add file textcomment */}
              <InputTextarea
                {...formikProps}
                name='comment'
                label={i18n.t('main.workDetail')}
              />

              <div className='tr'>
                <div className='th'>{i18n.t('main.recruitmentShiftsType')}</div>
                <div className='td'>
                  {availableShift.recruitmentShiftsType === 'SINGLE'
                    ? '個別作成'
                    : '定期作成'}
                </div>
              </div>

              <div className='tr'>
                <div className='th'>{i18n.t('main.postStatus')}</div>
                <div className='td'>
                  <div className='checkbox-item toggle-published'>
                    {isChangeRecruitmentShiftsStatusPermitted ? (
                      <Switch
                        name='isPublished'
                        checkedChildren='掲載中'
                        unCheckedChildren='未掲載'
                        defaultChecked={values.isPublished}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isPublished', val)
                          if (values.isSettingHideShift) {
                            setFieldValue('isSettingHideShift', false)
                          }
                        }}
                        disabled={values.isSetIndividually}
                        checked={values.isPublished}
                      />
                    ) : (
                      <Switch
                        checkedChildren='掲載中'
                        unCheckedChildren='未掲載'
                        name='isPublished'
                        checked={values.isPublished}
                        onChange={() => {
                          popup.error('募集シフト掲載選択の権限がありません。')
                        }}
                        disabled={values.isSetIndividually}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='tr'>
                <div className='th'>自動未掲載設定</div>
                <div className='td'>
                  <div className='checkbox-item toggle-published'>
                    {isChangeRecruitmentShiftsStatusPermitted ? (
                      <Switch
                        name='isSettingHideShift'
                        checkedChildren='設定'
                        unCheckedChildren='未設定'
                        defaultChecked={values.isSettingHideShift}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isSettingHideShift', val)
                        }}
                        disabled={!values.isPublished}
                        checked={values.isSettingHideShift}
                      />
                    ) : (
                      <Switch
                        name='isSettingHideShift'
                        checkedChildren='設定'
                        unCheckedChildren='未設定'
                        onChange={() => {
                          popup.error('募集シフト掲載選択の権限がありません。')
                        }}
                        disabled
                        checked={values.isSettingHideShift}
                      />
                    )}
                  </div>
                </div>
              </div>

              {values.isSettingHideShift && (
                <>
                  <div className='tr'>
                    <div className='th'>未掲載日</div>
                    <div className='td'>
                      <DatePicker
                        onChange={e => {
                          setFieldValue('dateSettingHideShift', e)
                          setFieldValue('timeSettingHideShift', '')
                        }}
                        value={values.dateSettingHideShift}
                        locale={locale}
                        allowClear={false}
                        disabledDate={disabledDate}
                      />
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>未掲載時間</div>
                    <div className='td'>
                      <TimePicker
                        // onChange={handleFilter}
                        placeholder='時間を設定'
                        format='HH:mm'
                        onChange={e => {
                          setFieldValue('timeSettingHideShift', e)
                        }}
                        value={values.timeSettingHideShift}
                        hideDisabledOptions
                        disabledMinutes={() => disabledMinute(60)}
                        allowClear={false}
                        showNow={false}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className='staffpage-comment-container'>
                <div className='staffpage-title'>募集項目</div>
                <ShiftFormRecruitmentCategoryDetail
                  availableShiftId={availableShiftId}
                  recruitmentCategory={recruitmentCategory}
                  recruitmentCategoryScheduleGroup={
                    recruitmentCategoryScheduleGroup
                  }
                />
              </div>
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createShift.setPublicRecruitmentShift')}
                </div>
                <div className='td'>
                  <Checkbox
                    onChange={e => {
                      if (!isChangeRecruitmentShiftsStatusPermitted) {
                        popup.error('募集シフト掲載選択の権限がありません。')
                      } else {
                        setFieldValue('isSetIndividually', e.target.checked)
                        if (e.target.checked) {
                          setFieldValue(
                            'objectTarget',
                            objectTargetConstant.SET_FOR_EACH_DOCTOR
                          )
                        } else {
                          setFieldValue('objectTarget', undefined)
                        }
                        setFieldValue('isPublished', false)
                      }
                    }}
                    checked={values.isSetIndividually}
                  />
                </div>
              </div>
              {values.isSetIndividually && (
                <ShiftFormDoctorPicker
                  {...formikProps}
                  hasPermitted={() => {
                    if (!isChangeRecruitmentShiftsStatusPermitted) {
                      popup.error(
                        i18n.t(
                          'staff.scheduledShiftGroup.errorMessagePermision'
                        )
                      )
                      return false
                    }
                    return true
                  }}
                  i18n={i18n}
                  listObjectTarget={listObjectTarget}
                  listWorkPartten={listWorkPartten}
                  isDisplay={values.isSetIndividually}
                  formikProps={formikProps}
                  isCreateAvailableShift={false}
                />
              )}

              {isMoneyPermitted ? (
                <>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.postUpdateHistory')}</div>
                    <div className='td'>
                      {activityHistories &&
                        activityHistories.map((it, k) => (
                          <div key={k}>
                            <span>
                              {moment(it.createdAt)
                                .startOf('day')
                                .format('YYYY年MM月DD日')}
                            </span>
                            <span>
                              {' '}
                              {moment(it.createdAt).format('HH:mm')}{' '}
                            </span>
                            <span> {it.staff} </span>
                            <span> {it.activity} </span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.updateHistory')}</div>
                    <div className='td'>
                      {updateHistories.map((it, k) => (
                        <div key={k}>
                          <span>
                            {moment(it.createdAt)
                              .startOf('day')
                              .format('YYYY年MM月DD日')}
                          </span>
                          <span> {moment(it.createdAt).format('HH:mm')} </span>
                          <span> {it.staff} </span>
                          <span>
                            {i18n.t(
                              `staff.activityLogsStaffHistory.${it.activity}`
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                undefined
              )}
              <div className='modal-bottom'>
                {isDeletePermitted ? (
                  <Button
                    label={
                      isPeriodical
                        ? i18n.t('staff.availableShift.deletePeriodical')
                        : i18n.t('staff.availableShift.delete')
                    }
                    onClick={() => {
                      setFieldValue('functionName', remove)
                      setFieldValue('confirm', true)
                    }}
                    icon='pi pi-trash'
                    className='p-button-danger'
                  />
                ) : null}
                {isUpdatePermitted ? (
                  <Button
                    label={
                      isPeriodical
                        ? i18n.t('staff.availableShift.updatePeriodical')
                        : i18n.t('staff.availableShift.update')
                    }
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('functionName', update)
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    disabled={isEditAvailableShiftThePast ? true : !dirty}
                    icon='pi pi-pencil'
                  />
                ) : null}

                {isCreateWsPermitted ? (
                  <Button
                    label={i18n.t('staff.availableShift.connect')}
                    disabled={dirty}
                    onClick={() => {
                      setFieldValue('functionName', connectToDoctor)
                      setFieldValue('confirm', true)
                    }}
                  />
                ) : null}
                <Link
                  to={{
                    pathname: `${location.pathname
                      .split('/')
                      .slice(0, -2)
                      .join('/')}${Router.staffCreateAvailableShift}`,
                    state: values,
                  }}
                >
                  <Button
                    style={{backgroundColor: 'white !important'}}
                    label={i18n.t('staff.availableShift.replicate')}
                  />
                </Link>
                {/* <ModalLink to={Router.staffCreateAvailableShift}>
                  <Button
                    icon='pi pi-plus'
                    className='p-button-normal-empty'
                    label={i18n.t('staff.availableShift.replicate')}
                  />
                </ModalLink> */}
                {/* <Button
                  label={i18n.t('staff.availableShift.replicate')}
                  icon='pi pi-plus'
                  disabled={dirty}
                  onClick={() => {
                    setFieldValue('functionName', replicate)
                    setFieldValue('confirm', true)
                  }}
                /> */}

                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={confirmationMessage}
                  handleSubmit={() => {
                    if (
                      values.functionName === connectToDoctor &&
                      !values.doctor
                    ) {
                      setFieldError(
                        'doctor',
                        i18n.t('validation.doctor.required')
                      )
                    } else {
                      handleSubmit()
                    }
                  }}
                  handleNoClick={() => {
                    setFieldValue('functionName', '')
                    setFieldValue('confirm', false)
                    setFieldValue('doctor', '')
                    setFieldValue('doctors', doctors)
                    setFieldValue('acceptedReason', '')
                  }}
                >
                  {values.functionName === connectToDoctor && (
                    <div className='tr'>
                      <div className='th'>{i18n.t('main.workplace')}</div>
                      <div className='td'>
                        {`${values.clinicName}_${values.departmentName}`}
                      </div>
                      <div className='th'>
                        {i18n.t('staff.createShift.doctor')}
                      </div>
                      <div className='td'>
                        <AutoComplete
                          inputStyle={{
                            width: '30vw',
                            maxWidth: '350px',
                          }}
                          value={values.doctor}
                          suggestions={newDoctors}
                          completeMethod={e => {
                            const results = doctors.filter(
                              doctor =>
                                doctor.fullName
                                  .toLowerCase()
                                  .includes(e.query.toLowerCase()) ||
                                doctor.fullNameKana
                                  .toLowerCase()
                                  .includes(e.query.toLowerCase()) ||
                                doctor.email
                                  .toLowerCase()
                                  .includes(e.query.toLowerCase())
                            )
                            setFieldValue('doctors', results)
                          }}
                          minLength={1}
                          placeholder='医師を選択してください'
                          field='fullNameEmail'
                          onUnselect={() => {
                            setFieldValue('doctor', null)
                          }}
                          onSelect={e => {
                            if (e.value?.countedDoctorLastWorkShift?.lastWorkday) {
                              const dateAfterEighteenMonths = moment(e.value.countedDoctorLastWorkShift.lastWorkday)
                                .add(18, 'months')
                                .startOf('D')
                                .toISOString()
                              const now = moment().toISOString();
                              if (now > dateAfterEighteenMonths) {
                                setShowModalDoctorNotValid(true);
                              }
                            }
                            setFieldValue('doctor', e.value)
                            if (e.value) {
                              getNoDoctorWorked({
                                variables: {
                                  doctorId: e.value.id,
                                  clinicId:
                                  availableShift.clinicalDepartment.clinic.id,
                                },
                              })
                            }
                            if (e.value.firstWorkday === null) {
                              setFieldValue('isFirstWork', true)
                              setFieldValue(
                                'acceptedReason',
                                DetailAvailableShiftTemplateMail.props.children
                              )
                            } else {
                              setFieldValue('isFirstWork', false)
                              setFieldValue('acceptedReason', '')
                            }
                          }}
                          onChange={async e => {
                            console.log(456)
                            if (typeof e.value === 'string') {
                              try {
                                setFieldValue('doctor', e.value)
                                const {
                                  data: {doctorsConnection},
                                } = await client.query({
                                  query: DOCTORS_CONNECTION,
                                  variables: {
                                    where: {
                                      OR: [
                                        {
                                          OR: [
                                            {
                                              unSigned_contains: e.value
                                                ? e.value
                                                  .replace(/ /g, '')
                                                  .replace(/　/g, '')
                                                  .toLowerCase()
                                                : '',
                                            },
                                          ],
                                        },
                                        {
                                          email_contains: e.value,
                                        },
                                      ],
                                    },
                                    orderBy: 'unSigned_ASC',
                                    first: 10,
                                    skip: 0,
                                  },
                                  fetchPolicy: 'network-only',
                                })
                                setNewDoctors(
                                  convertDoctorsConnection(
                                    doctorsConnection.edges
                                  )
                                )
                              } catch (err) {
                                popup.error()
                              }
                            }
                          }}
                          dropdown
                        />
                      </div>
                      <div className='th'>{i18n.t('main.workTypes.title')}</div>
                      <div className='td'>
                        <CustomDropdown
                          {...formikProps}
                          style={{
                            minWidth: '300px',
                          }}
                          name='type'
                          options={options}
                          placeholder={i18n.t('main.workTypes.placeholder')}
                        />
                      </div>

                      <div className='th'>
                        {i18n.t('main.workTypes.firstWork')}
                      </div>
                      <div className='td'>
                        {values.doctor && values.doctor.firstWorkday
                          ? moment(values.doctor.firstWorkday).format('LL ')
                          : '法人初勤務です'}
                      </div>
                      <div className='th'>
                        {i18n.t('staff.workSchedule.noDoctorWorkedInClinic')}
                      </div>
                      <div className='td'>{countDoctorWorkedInClinic}</div>
                      <div className='th'>
                        {i18n.t('main.workTypes.firstWorkMessage')}
                      </div>
                      <div className='td'>
                        <Checkbox
                          checked={values.isFirstWork}
                          onChange={() => {
                            setFieldValue('isFirstWork', !values.isFirstWork)
                            if (!values.isFirstWork) {
                              setFieldValue(
                                'acceptedReason',
                                DetailAvailableShiftTemplateMail.props.children
                              )
                              setFieldValue('isSendIntroduceMess', false)
                            } else {
                              setFieldValue('acceptedReason', '')
                            }
                          }}
                        />
                      </div>
                      <div className='th'>
                        {i18n.t('staff.workSchedule.isSendIntroduceMess')}
                      </div>
                      <div className='td'>
                        <Checkbox
                          onChange={e => {
                            setFieldValue(
                              'isSendIntroduceMess',
                              e.target.checked
                            )
                            if (e.target.checked) {
                              setFieldValue(
                                'acceptedReason',
                                availableShift.clinicalDepartment.clinic
                                  .messageIntroduce
                              )
                              setFieldValue('isFirstWork', false)
                            } else {
                              setFieldValue('acceptedReason', '')
                            }
                          }}
                          checked={values.isSendIntroduceMess}
                        />
                      </div>
                      <div className='th'>
                        {i18n.t('staff.availableShift.reasonKnowAboutShift')}
                      </div>
                      <div className='td'>
                        <CustomDropdown
                          {...formikProps}
                          style={{
                            minWidth: '300px',
                          }}
                          name='introductionHistory'
                          options={optionsKnowAboutShift}
                          // placeholder={i18n.t('main.workTypes.placeholder')}
                          placeholder={i18n.t(
                            'staff.availableShift.placeholderSelectReasonKnowAboutShift'
                          )}
                        />
                      </div>
                      {values.introductionHistory === 'OTHER' ? (
                        <>
                          {/* <div className='th'>
                            {i18n.t(
                              'staff.availableShift.detailReasonKnowAboutShift'
                            )}
                          </div> */}
                          <div className='td'>
                            <InputTextarea
                              {...formikProps}
                              name='introductionHistoryOther'
                              label={i18n.t(
                                'staff.availableShift.detailReasonKnowAboutShift'
                              )}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className='td'>
                        <div className='tr'>
                          <div className='th'>
                            {i18n.t('main.createWorkScheduleReason')}
                          </div>
                          <div className='td'>
                            <Editor
                              className='editor-responsive'
                              handleSubmit={validateAndConfirm}
                              handleNoClick={() => {
                                setFieldValue('updating', false)
                                setFieldValue('acceptedReason', '')
                              }}
                              onTextChange={e =>
                                setFieldValue('acceptedReason', e.htmlValue)
                              }
                              value={values.acceptedReason}
                            />
                          </div>
                          <div className='alert'>{errors.acceptedReason}</div>
                        </div>
                        {/* <InputTextarea
                          {...formikProps}
                          handleSubmit={validateAndConfirm}
                          handleNoClick={() => {
                            setFieldValue('updating', false)
                            setFieldValue('acceptedReason', '')
                          }}
                          confirmationMessage={confirmationMessage}
                          visibleKey='updating'
                          name='acceptedReason'
                          label={i18n.t('main.createWorkScheduleReason')}
                        /> */}
                        <div className='alert'>{errors.doctor}</div>
                      </div>
                    </div>
                  )}
                </ConfirmationModal>
                <Modal
                  onCancel={() => setShowModalDoctorNotValid(false)}
                  visible={showModalDoctorNotValid}
                  title={''}
                  centered
                  closable={false}
                  footer={
                    <div style={{textAlign: 'center'}}>
                      <Button className='outline-red'
                              onClick={() => setShowModalDoctorNotValid(false)} label={'閉じる'}/>
                    </div>}
                    >
                    <span  style={{color: '#f84e51', fontWeight: '600'}}>
                      こちらの医師は最終勤務日から18ヶ月以上経過しているため、勤務前にオリエンテーションの必要があります。
                    </span>
                </Modal>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default withRouter(DetailAvailableShiftScene)
