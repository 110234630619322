import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownInSearch'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button, Button as PrimeButton } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import {
  DoctorStatusTemplate,
  getStatus,
} from '../DoctorsPendingList/DoctorStatusTemplate'
import {
  DOWNLOAD_CSV_DOCTOR_INFO,
  DOWNLOAD_CSV_DOCTOR_PENDING,
} from '@medical/constant'
import moment from "moment/moment";

const styles = {
  status: {
    color: '#f84e51',
    fontWeight: 'bold',
  },
}

const doctorRegistrationStatus = node => {
  const { registrationStatus } = node
  if (registrationStatus === 'NEW') {
    return <td style={styles.status}>{getStatus(node.registrationStatus)}</td>
  }
  return <td>{getStatus(node.registrationStatus)}</td>
}

const DoctorListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  phoneNumber,
  status,
  edges,
  count,
  onPageChange,
  download,
  onSubmit,
  restore,
  block,
  isDoctorsFieldsPermitted,
  isUpdatePermitted,
  isBlockPermitted,
  isRestorePermitted,
  isCreatePermitted,
  departmentNames,
  isUploadPermitted,
  isDoctorsPendingList,
  isUploadUpdatePermitted,
  socket,
  progressList,
  setProgress,
  removeProgress,
}) => {
  const progress = useMemo(
    () =>
      download.includes('downloadcsvDoctorPending')
        ? DOWNLOAD_CSV_DOCTOR_PENDING
        : DOWNLOAD_CSV_DOCTOR_INFO,
    [download]
  )
  const hasProgress = Object.keys(progressList).includes(progress)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(progress, data => {
        if (data?.percent) {
          setProgress({
            progress: progress,
            label: '医師情報CSVダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {isDoctorsPendingList
            ? i18n.t('staff.menuBar.doctorsPending')
            : i18n.t('staff.menuBar.doctors')}
          <BreadCrumb
            items={[
              {
                title: isDoctorsPendingList
                  ? i18n.t('staff.menuBar.doctorsPending')
                  : i18n.t('staff.menuBar.doctors'),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {isDoctorsPendingList ? null : (
            <Link to={Router.get(Router.staffDoctorSkill)}>
              <PrimeButton
                icon='pi pi-plus'
                className='p-button-normal'
                label='医師スキル設定'
              />
            </Link>
          )}
          {isDoctorsFieldsPermitted ? (
            // <ExportDoctorCSV count={count} variables={variables} />
            <PrimeButton
              icon='pi pi-download'
              className='p-button-normal'
              label='医師情報CSVダウンロード'
              onClick={async () => {
                setProgress({
                  progress,
                  label: '医師情報CSVダウンロード中',
                  percent: 0,
                })
                try {
                  const { status, data: urlS3 } = await Axios({
                    method: 'GET',
                    url: download,
                  })
                  await waitFor(3000)
                  if (status === 204) {
                    setProgress({
                      progress,
                      label: '医師情報CSVダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  } else {
                    setProgress({
                      progress,
                      percent: 100,
                      url: urlS3,
                    })
                  }
                } catch (err) {
                  setProgress({
                    progress,
                    label: '医師情報CSVダウンロード中',
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                }
                removeProgress(progress)
              }}
            />
          ) : null}
          {
            // Doctor all information csv export batch has hidden.
            /* {isDoctorsFieldsPermitted ? (
          <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={download}
          />
        ) : null} */
          }
          {isUploadPermitted ? (
            <Link to={Router.get(Router.staffUploadDoctorCsv)}>
              <Button
                icon='pi pi-cloud-upload'
                className='p-button-normal'
                label='医師情報CSVアップロード（新規登録）'
              />
            </Link>
          ) : null}
          {isUploadUpdatePermitted ? (
            <Link to={Router.get(Router.uploadDoctorCsvUpdate)}>
              <Button
                icon='pi pi-cloud-upload'
                className='p-button-normal'
                label='医師情報CSVアップロード（更新）'
              />
            </Link>
          ) : null}
          {isCreatePermitted ? (
            <ModalLink to={Router.get(Router.staffDoctorCreate)}>
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t('staff.createDoctor.submit')}
              />
            </ModalLink>
          ) : null}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              email,
              phoneNumber,
              status,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='search-parent'>
                  <InputText
                    name='fullname'
                    value={values.fullname}
                    placeholder={i18n.t('main.fullname')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <InputText
                    name='email'
                    value={values.email}
                    placeholder={i18n.t('main.email')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  {!isDoctorsPendingList ? (
                    <InputText
                      name='phoneNumber'
                      value={values.phoneNumber}
                      placeholder='電話番号'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                  ) : null}
                  {isDoctorsPendingList ? (
                    <CustomDropdown
                      {...formikProps}
                      options={DoctorStatusTemplate}
                      name='status'
                      defaultTitle={i18n.t('doctor.myPage.status')}
                    />
                  ) : null}
                  <Link
                    to={{
                      pathname: isDoctorsPendingList
                        ? Router.staffDoctorsPending
                        : Router.staffDoctors,
                      search: queryString.stringify({
                        rowsPerPage,
                        fullname: values.fullname,
                        email: values.email,
                        status: values.status,
                        phoneNumber: values.phoneNumber,
                      }),
                    }}
                    style={{ marginLeft: '20px' }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {fullname || email || status || phoneNumber ? (
                    <Link
                      to={{
                        pathname: isDoctorsPendingList
                          ? Router.staffDoctorsPending
                          : Router.staffDoctors,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
              doctorId: '',
              functionName: '',
            }}
            onSubmit={onSubmit}
            render={formikProps => {
              const { values, setFieldValue } = formikProps
              let confirmationMessage
              switch (values.functionName) {
                case block:
                  confirmationMessage = i18n.t(
                    'staff.blockDoctor.submitConfirmation'
                  )
                  break

                case restore:
                  confirmationMessage = i18n.t(
                    'staff.restoreBlockedDoctor.submitConfirmation'
                  )
                  break
                default:
                  break
              }

              return (
                <div className='overflow-container'>
                  <table className='table'>
                    <thead>
                    <tr>
                      <th width='15%'>{i18n.t('updateProfile.fullname')}</th>
                      <th width='15%'>{i18n.t('updateProfile.email')}</th>
                      <th width='10%'>
                        {i18n.t('updateProfile.phoneNumber')}
                      </th>
                      <th width='10%'>
                        {i18n.t('updateProfile.departments')}
                      </th>
                      <th width='10%'>
                        {i18n.t('updateProfile.homeAddress.address')}
                      </th>
                      <th width='10%'>
                        {i18n.t('updateProfile.placeOfWork')}
                      </th>
                      <th width='10%'>
                        {i18n.t('updateProfile.nearestStations')}
                      </th>
                      <th width='10%'>
                        最終勤務日
                      </th>
                      {isDoctorsPendingList ? (
                        <th width='10%'>{i18n.t('doctor.myPage.status')}</th>
                      ) : null}
                      {isUpdatePermitted ||
                      isBlockPermitted ||
                      isRestorePermitted ? (
                        <th width='5%'>{i18n.t('main.action')}</th>
                      ) : null}
                    </tr>
                    </thead>
                    <tbody>
                    {edges.map(({node}) => (
                      <tr
                          key={node.id}
                          className={node.blocked ? 'table-block' : ''}
                        >
                          <td>{`${node.lastname} ${node.firstname}`}</td>
                          <td>
                            <a href={`mailto:${node.email}`}>{node.email}</a>
                          </td>
                          <td>{node.phoneNumber}</td>
                          <td>
                            <div className='flex-parent'>
                              {node.departments.sort().map(department => (
                                <div className='slashed-item' key={department}>
                                  {departmentNames[department]}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            {pathOr('', ['homeAddress', 'stateOrRegion'], node)}
                            {pathOr('', ['homeAddress', 'address1'], node)}
                            {pathOr('', ['homeAddress', 'address2'], node)}
                          </td>
                          <td>{node.placeOfWork}</td>
                          <td>{node.nearestStations}</td>
                          <td>{node.countedDoctorLastWorkShift.lastWorkday ? moment(node.countedDoctorLastWorkShift.lastWorkday).format('YYYY年MM月DD日') : "" }</td>
                          {isDoctorsPendingList
                            ? doctorRegistrationStatus(node)
                            : null}
                          {isUpdatePermitted ||
                          isBlockPermitted ||
                          isRestorePermitted ? (
                            <td>
                              <div className='action-doctor-list'>
                                {isUpdatePermitted ? (
                                  <ModalLink
                                    to={Router.get(Router.staffDoctorDetail, {
                                      doctorId: node.id,
                                    })}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </ModalLink>
                                ) : null}
                                {!node.blocked && isBlockPermitted ? (
                                  <Button
                                    onClick={() => {
                                      setFieldValue('doctorId', node.id)
                                      setFieldValue('functionName', block)
                                      setFieldValue('confirm', true)
                                    }}
                                    className='p-button-danger'
                                    icon='pi pi-ban'
                                  />
                                ) : null}
                                {node.blocked && isRestorePermitted ? (
                                  <Button
                                    onClick={() => {
                                      setFieldValue('doctorId', node.id)
                                      setFieldValue('functionName', restore)
                                      setFieldValue('confirm', true)
                                    }}
                                    icon='pi pi-check-circle'
                                  />
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={confirmationMessage}
                  />
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DoctorListScene
