import gql from 'graphql-tag'

export const DECLARATION_CHANGE_HISTORIES_CONNECTION = gql`
  query GetDeclarationChangeHistories(
    $orderBy: DeclarationChangeHistoryOrderByInput
    $first: Int
    $skip: Int
    $doctorName: String
    $locationName: String
    $workDay: DateTime
  ) {
    getDeclarationChangeHistories(
      doctorName:  $doctorName
      locationName: $locationName
      workDay: $workDay
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      hasPreviousPage
      hasNextPage
      totalCount
      data {
        createdAt
        workDay
        id
        workStatus
        notes
        Clinic {
          id
          name
        }
        Doctor {
          firstname
          id
          lastname
          fullName
        }
        createdBy
        Staff_DeclarationChangeHistory_createdByToStaff {
          email
          firstname
          id
          lastname
        }
      }
    }
  }

`